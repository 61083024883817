import React from "react";
import "./CustomButton.scss";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

function CustomButton({
  type,
  className,
  onClick = () => {},
  children,
  color,
  textColor,
  style,
  disabled = false,
  title = "",
  tooltip,
  placement = "bottom",
}) {
  return (
    <div className="custom-button-container">
      <OverlayTrigger
        placement={placement}
        overlay={tooltip ? <Tooltip id={tooltip}>{tooltip}</Tooltip> : <></>}
      >
        <button
          disabled={disabled}
          type={type}
          className={`custom-button-main ${className}`}
          onClick={onClick}
          style={{ backgroundColor: color, color: textColor, ...style }} // Setting button color using inline style
        >
          {title}
          {children}
        </button>
      </OverlayTrigger>
    </div>
  );
}

export default CustomButton;
