import {
  mdiAccountOutline,
  mdiCarBack,
  mdiFileDocumentOutline,
  mdiPlus,
} from "@mdi/js";

export const EDIT_LEAD_DETAILS = [
  {
    id: 1,
    title: "LAST POLICY DETAILS",
    editLabel: "Last Policy Details",
    keys: "last",
    icons: mdiFileDocumentOutline,
  },
  {
    id: 2,
    title: "ADD-ON & PA COVER DETAILS",
    editLabel: "Add-On & PA Cover Details",
    keys: "add",
    icons: mdiPlus,
  },
  {
    id: 3,
    title: "VEHICLE DETAILS",
    editLabel: "Vehicle Details",
    keys: "vehicle",
    icons: mdiCarBack,
  },
  {
    id: 4,
    title: "OWNER DETAILS",
    editLabel: "Owner Details",
    keys: "owner",
    icons: mdiAccountOutline,
  },
];

export const convertedDetailsInReduxFormate = (data) => {
  console.log("Edit Details", data);
};
