import Icon from "@mdi/react";
import React from "react";

const LeadListCard = ({ selectProduct, product, onClick, title }) => {
  // console.log("onClick<><><>", onClick);
  return (
    <div className="edit-lead-card-container">
      <div
        className={`edit-lead-card ${
          selectProduct?.id === product?.id ? "selected" : ""
        }`}
        onClick={onClick}
      >
        <div className="product-name text-blue">
          <Icon path={product?.icons} size={0.8} className="" />
          <div className="fw-bold ">{title}</div>
        </div>
        <i className="bi bi-chevron-right" />
      </div>
    </div>
  );
};

export default LeadListCard;
