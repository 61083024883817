import React from "react";
import ForgotPasswordComponent from "../../components/pages/VehicleInsurenceComponent/ForgotPasswordComponent";

const ForgotPasswordPage = () => {
  return <>
  <ForgotPasswordComponent/>
  </>;
};

export default ForgotPasswordPage;
