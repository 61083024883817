import React, { useState } from "react";
import "./InboundLead.scss";
import ROUTES_NAVIGATION from "../../../../routes/routes";
import MainCard from "../../../shared/MainCard";
import CustomButton from "../../../shared/CustomButton";
import CustomSelect from "../../../shared/CustomSelect";
import CustomInput from "../../../shared/CustomInput";
import CustomSearch from "../../../shared/CustomSearch";
import InboundTableList from "./InboundListTable";
import { INBOUND_LIST_DATA } from "./InboundListDefaultData";
import { useNavigate } from "react-router-dom";

const InboundLeadListComponent = () => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const PAGE_ROUTE = [
    {
      path: "/",
      label: "Home",
    },
    {
      path: ROUTES_NAVIGATION.VEHICLE_INSURENCE_INBOUND_LEAD,
      label: "Inbound Lead",
    },
  ];
  return (
    <MainCard
      title="Inbound Lead"
      pageRoutes={PAGE_ROUTE}
      activeButtons={
        <div className="d-flex gap-2 inbound-main">
          {/* <CustomButton
            className={"rounded-2  qutation-btn"}
            style={{
              fontSize: "0.8rem",
            }}
            onClick={() => {
              setIsModalOpen(true);
              // setIsAddTaskListModal(true);
            }}
          >
            <i className="bi bi-card-list"></i>
            Send Questionnaire
          </CustomButton> */}
          {/* <CustomButton
            className={"rounded-2 text-dark-teal"}
            onClick={() => {
              // setIsAddTaskListModal(true);
            }}
          >
            <i className="bi bi-three-dots-vertical"></i>
          </CustomButton> */}
          {/* <CustomButton
            className={"text-blue rounded-2 bg-teal"}
            onClick={() => {
              // setIsAddTaskListModal(true);
            }}
          >
            <i className="bi bi-plus"></i>
          </CustomButton> */}
          <div className="me-1">
            <CustomButton className="btn download-btn">
              <i className="bi bi-download"></i>
              <span className="">Download</span>
            </CustomButton>
          </div>
        </div>
      }
    >
      <div className="">
        <div className="pb-3">
          <div className="row g-2">
            <div className="col-12  col-sm-4 col-md-3 col-lg-2">
              <CustomSelect placeholder={"IMD Code"} />
            </div>
            <div className="col-12  col-sm-4 col-md-3 col-lg-2">
              <CustomSelect placeholder={"Engine Number"} />
            </div>
            <div className="col-12 col-sm-4 col-md-3 col-lg-8">
              <CustomSearch />
            </div>
          </div>
        </div>
      </div>
      <div className="card mb-4 pt-3">
        <InboundTableList
          tableRow={INBOUND_LIST_DATA}
          // onLeadIDClick={(row) => {
          //   navigate(
          //     ROUTES_NAVIGATION.VEHICLE_INSURENCE_LEAD_DETAILS + "?id=" + row.id
          //   );
          // }}
        />
      </div>
    </MainCard>
  );
};

export default InboundLeadListComponent;
