import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import CustomDateInput from "../../../../shared/CustomDateInput";
import { useDispatch, useSelector } from "react-redux";
import { setFormManagerAsPerKey } from "../../../../../redux/FormData/reduxFormManage";
import moment from "moment";

const OwnerDetails = ({ ownerDetails }) => {
  const [dob, setDob] = useState(null);
  const [address, setAddress] = useState("");
  const dispatch = useDispatch();
  const {
    control,
    formState: { errors },
  } = useForm({ reValidateMode: "onChange", mode: "onChange" });
  useEffect(() => {
    setDob(ownerDetails?.DOB || null);
    setAddress(ownerDetails?.Address);
  }, [ownerDetails?.Address, ownerDetails?.DOB]);
  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-6" style={{ padding: "5px 5px 15px" }}>
          <Controller
            control={control}
            name={"fullName"}
            rules={{
              maxLength: {
                value: 25,
                message: `Max 25 characters allowed`,
              },
            }}
            render={({
              field: { onChange, onBlur, value },
              fieldState: { error },
            }) => (
              <div className="custom-input-wrapper">
                <label htmlFor="customInput" className="form-label">
                  {"Full Name"}
                </label>
                <div className="input-with-prefix">
                  <input
                    type={"text"}
                    className="form-control form-control-lg"
                    placeholder={"Enter Full Name"}
                    value={ownerDetails?.FullName}
                    onChange={(e) => {
                      onChange(e);
                    }}
                    onBlur={onBlur}
                    disabled
                  />
                </div>
                {error && (
                  <div className="error-message-input">{error.message}</div>
                )}
              </div>
            )}
          />
        </div>

        <div className="col-sm-6" style={{ padding: "5px 5px 15px" }}>
          <Controller
            control={control}
            name={"customerId"}
            rules={{
              required: {
                value: true,
                message: "This field is required to be filled*",
              },
              maxLength: {
                value: 25,
                message: `Max 25 characters allowed`,
              },
            }}
            render={({
              field: { onChange, onBlur, value },
              fieldState: { error },
            }) => (
              <div className="custom-input-wrapper">
                <label htmlFor="customInput" className="form-label">
                  {"Customer Id"}
                </label>
                {/* <span className="text-danger">*</span> */}
                <div className="input-with-prefix">
                  <input
                    type={"text"}
                    className="form-control form-control-lg"
                    placeholder={"Enter Customer Id"}
                    value={ownerDetails?.CustomerID}
                    onChange={(e) => onChange(e)}
                    onBlur={onBlur}
                    disabled
                  />
                </div>
                {error && (
                  <div className="error-message-input">{error.message}</div>
                )}
              </div>
            )}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6" style={{ padding: "5px 5px 15px" }}>
          <Controller
            control={control}
            name={"dob"}
            render={({
              field: { onChange, onBlur, value },
              fieldState: { error },
            }) => (
              <div className="custom-input-wrapper">
                <label htmlFor="customInput" className="form-label">
                  {"DOB"}
                </label>
                {/* <span className="text-danger">*</span> */}
                <div className="input-with-prefix">
                  {/* <CustomDateInput
                    // label="From"
                    placeholderText="DD/MM/YYYY"
                    isDateRange={false}
                    className="from-date-input ms-1"
                    // selected={startDate}
                    onChange={(date) => {
                      // setStartDate(date || null);
                    }}
                  /> */}
                  <CustomDateInput
                    placeholderText="DD/MM/YYYY"
                    // minDate={new Date()}
                    isDateRange={false}
                    className="from-date-input ms-1"
                    selected={moment(ownerDetails?.DOB, [
                      "YYYY-MM-DD",
                      "YYYY-DD-MM",
                      "DD-MM-YYYY",
                    ]).format("yyyy-MM-DD")}
                    onChange={(date) => {
                      console.log("@@@", date);
                      // setDob(date);
                      dispatch(
                        setFormManagerAsPerKey({
                          type: "Owner_Details",
                          key: "DOB",
                          value: moment(date, [
                            "YYYY-MM-DD",
                            "YYYY-DD-MM",
                            "DD-MM-YYYY",
                          ]).format("yyyy-MM-DD"),
                        })
                      );
                    }}
                  />
                </div>
                {error && (
                  <div className="error-message-input">{error.message}</div>
                )}
              </div>
            )}
          />
        </div>
        <div className="col-sm-6" style={{ padding: "5px 5px 15px" }}>
          <Controller
            control={control}
            name={"mobileNumber"}
            rules={{
              required: {
                value: true,
                message: "This field is required to be filled*",
              },
              maxLength: {
                value: 25,
                message: `Max 25 characters allowed`,
              },
            }}
            render={({
              field: { onChange, onBlur, value },
              fieldState: { error },
            }) => (
              <div className="custom-input-wrapper">
                <label htmlFor="customInput" className="form-label">
                  {"Mobile Number"}
                </label>
                {/* <span className="text-danger">*</span> */}
                <div className="input-with-prefix">
                  <input
                    type={"text"}
                    className="form-control form-control-lg"
                    placeholder={"Enter Mobile Number"}
                    value={ownerDetails?.MobileNumber}
                    onChange={(e) => onChange(e)}
                    onBlur={onBlur}
                    disabled
                  />
                </div>
                {error && (
                  <div className="error-message-input">{error.message}</div>
                )}
              </div>
            )}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6" style={{ padding: "5px 5px 15px" }}>
          <Controller
            control={control}
            name={"leadEmail"}
            rules={{
              required: {
                value: true,
                message: "This field is required to be filled*",
              },
              pattern: {
                value:
                  /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.(com|in|org|co|net)$/,
                message: "Invalid Email Id format",
              },
            }}
            render={({
              field: { onChange, onBlur, value },
              fieldState: { error },
            }) => (
              <div className="custom-input-wrapper">
                <label htmlFor="customInput" className="form-label">
                  {"Email Id"}
                </label>
                {/* <span className="text-danger">*</span> */}
                <div className="input-with-prefix">
                  <input
                    type={"text"}
                    className="form-control form-control-lg"
                    placeholder={"Enter Email Id"}
                    value={ownerDetails?.EmailId}
                    onChange={(e) => {
                      // handleFormUpdate(value);
                      onChange(e);
                    }}
                    onBlur={onBlur}
                    disabled
                  />
                </div>

                {error && (
                  <div className="error-message-input">{error.message}</div>
                )}
              </div>
            )}
          />
        </div>

        <div className="col-sm-6" style={{ padding: "5px 5px 15px" }}>
          <Controller
            control={control}
            name={"address"}
            rules={{
              // required: {
              //   value: true,
              //   message: "This field is required to be filled*",
              // },
              maxLength: {
                value: 25,
                message: `Max 25 characters allowed`,
              },
            }}
            render={({
              field: { onChange, onBlur, value },
              fieldState: { error },
            }) => (
              <div className="custom-input-wrapper">
                <label htmlFor="customInput" className="form-label">
                  {"Address"}
                </label>
                {/* <span className="text-danger">*</span> */}
                <div className="input-with-prefix">
                  <input
                    type={"text"}
                    className="form-control form-control-lg"
                    placeholder={"Enter Address"}
                    value={address}
                    onChange={(e) => {
                      onChange(e);
                      // setAddress(e.target.value);
                      dispatch(
                        setFormManagerAsPerKey({
                          type: "Owner_Details",
                          key: "Address",
                          value: e.target.value,
                        })
                      );
                    }}
                    onBlur={onBlur}
                  />
                </div>
                {error && (
                  <div className="error-message-input">{error.message}</div>
                )}
              </div>
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default OwnerDetails;
