import React from "react";
import CustomInput from "../../../../shared/CustomInput";

const UpdatedLastPolicyDetails = ({ data }) => {

  return (
    <div className="mt-2">
      <div className="row">
        <div className="col-md-3">
          <div className="card">
            <div className="row m-2">
              <label>Previous Policy No</label>
              <CustomInput value={data?.prev_policy_number} disabled />
            </div>
            <div className="row m-2">
              <label>Updated Policy No</label>
              <CustomInput value={data?.updated_policy_number} disabled />
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="card">
            <div className="card">
              <div className="row m-2">
                <label>Previous Insurance Company</label>
                <CustomInput value={data?.prev_insurance_company_name} disabled />
              </div>
              <div className="row m-2">
                <label>Updated Insurance Company</label>
                <CustomInput  value={data?.updated_insurance_company_name} disabled />
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="card">
            <div className="card">
              <div className="row m-2">
                <label>Previous Coverage Period</label>
                <CustomInput value={data?.prev_policy_start_date +' to '+ data?.prev_policy_end_date } disabled />
              </div>
              <div className="row m-2">
                <label>Updated Coverage Period</label>
                <CustomInput value={data?.updated_policy_start_date +' to '+ data?.updated_policy_end_date   } disabled />
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="card">
            <div className="card">
              <div className="row m-2">
                <label>Previous Premium Amount</label>
                <CustomInput value={data?.prev_premium_amount} disabled />
              </div>
              <div className="row m-2">
                <label>Updated Premium Amount</label>
                <CustomInput value={data?.updated_premium_amount} disabled />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-md-3">
          <div className="card">
            <div className="card">
              <div className="row m-2">
                <label>Previous Last Year Discount (%)</label>
                <CustomInput value={data?.prev_prev_ncb} disabled />
              </div>
              <div className="row m-2">
                <label>Updated Last Year Discount (%)</label>
                <CustomInput value={data?.updated_prev_ncb} disabled />
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-3 ">
          <div className="card">
            <div className="card">
              <div className="row m-2">
                <label>Previous Claim During Last Year</label>
                <CustomInput value={data?.prev_prev_claims} disabled />
              </div>
              <div className="row m-2">
                <label>Updated Claim During Last Year</label>
                <CustomInput value={data?.updated_prev_claims} disabled />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdatedLastPolicyDetails;
