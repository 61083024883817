import React, { useState } from "react";
import { displayToast } from "../../../shared/toast/toastUtils";
import RightSideContainer from "../../../../pages/RightSideContainer";
import { useLocation, useNavigate } from "react-router-dom";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import quantique from "../../../../../src/assets/img/photos/quantique.svg";
import { APIUpdatePassword } from "../../../../api/common";
import "./ChangePassword.scss";

const ChangePasswordComponent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { email } = location.state || {};
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState({});

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const validateForm = () => {
    const validationErrors = {};
    if (!password) {
      validationErrors.passwordError = "Password cannot be empty.";
    } else if (password.length < 8) {
      validationErrors.passwordError =
        "Password must be at least 8 characters long.";
    }

    if (!confirmPassword) {
      validationErrors.confirmPasswordError = "Please confirm your password.";
    } else if (password !== confirmPassword) {
      validationErrors.confirmPasswordError = "Passwords do not match.";
    }

    setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0;
  };

  const handlePasswordChange = async () => {
    if (validateForm()) {
      try {
        const payload = {
          email,
          new_password: password,
          reentered_password: confirmPassword,
        };
        const response = await APIUpdatePassword(payload);
        if (response?.status === 200) {
          displayToast({
            type: "success",
            title: "Password changed successfully!",
          });
          navigate("/login");
        } else {
          displayToast({
            type: "error",
            title: response.data?.message || "Failed to change password.",
          });
        }
      } catch (error) {
        displayToast({
          type: "error",
          title: "Something went wrong. Please try again.",
        });
      }
    }
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <RightSideContainer />
        <div
          className="col-md-6 d-flex align-items-center justify-content-center"
          style={{ backgroundColor: "white" }}
        >
          <div className="forgot-pass-box">
            <div className="mb-5">
              <img src={quantique} alt="Quantique Logo" className="img-fluid" />
            </div>
            <>
              <h4 className="mb-4 text-black">Change Your Password</h4>
              <form>
                <div className="form-group mb-4">
                  <label className="font-8 text-gray">New Password</label>
                  <div
                    className="input-wrapper"
                    style={{ position: "relative" }}
                  >
                    <input
                      required
                      type={showPassword ? "text" : "password"}
                      className="form-control mt-2 text-left"
                      id="newPassword"
                      placeholder="Enter new password"
                      value={password}
                      onChange={(e) => {
                        setPassword(e.target.value);
                        setErrors((prev) => ({ ...prev, passwordError: "" }));
                      }}
                    />
                    <span
                      className="password-toggle-icon"
                      onClick={togglePasswordVisibility}
                      style={{
                        position: "absolute",
                        right: 10,
                        top: "50%",
                        transform: "translateY(-50%)",
                        cursor: "pointer",
                      }}
                    >
                      {showPassword ? <BsEyeSlash /> : <BsEye />}
                    </span>
                  </div>
                  {errors.passwordError && (
                    <div className="error-message text-danger mt-1">
                      {errors.passwordError}
                    </div>
                  )}
                </div>
                <div className="form-group mb-4 input-wrapper">
                  <label className="font-8 text-gray">
                    Confirm Password <span className="text-danger">*</span>
                  </label>
                  <input
                    required
                    type={showPassword ? "text" : "password"}
                    className="form-control mt-2 text-left"
                    id="confirmPassword"
                    placeholder="Re-enter new password"
                    value={confirmPassword}
                    onChange={(e) => {
                      setConfirmPassword(e.target.value);
                      setErrors((prev) => ({
                        ...prev,
                        confirmPasswordError: "",
                      }));
                    }}
                  />
                  {errors.confirmPasswordError && (
                    <div className="error-message text-danger mt-1">
                      {errors.confirmPasswordError}
                    </div>
                  )}
                </div>
              </form>
              <button
                className="col-6 btn btn-block login-button"
                style={{
                  backgroundColor: "#4285F4",
                  color: "white",
                  padding: "13px",
                  borderRadius: "10px",
                  width: "100%",
                }}
                onClick={handlePasswordChange}
              >
                Change Password
              </button>
            </>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePasswordComponent;
