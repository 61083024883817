import { create } from "apisauce";
import Cookies from "universal-cookie";
import settings from "./settings";
import { useNavigate } from "react-router-dom";
// import axios from "axios";
// import AsyncStorage from '@react-native-async-storage/async-storage';

const cookies = new Cookies();
const getToken = async () => {
  try {
    return await cookies.get("access_token");
  } catch (e) {
    console.log("Error getting access token: " + e);
  }
};

const privateAPIClient = create({
  baseURL: settings.LEAD_API_BASE_URL,
  // authURL: settings.AUTH_API_BASE_URL,
});

privateAPIClient.addAsyncRequestTransform(async (request) => {
  const authToken = await getToken();
  console.log("🚀 lead", authToken);
  if (!authToken) {
    console.log("------invalid Access------");
    return;
  }

  request.headers["Authorization"] = "Bearer " + authToken;
  // request.headers["Content-Type"] = "multipart/form-data";
  request.headers["Content-Type"] = "application/json";
  // request.headers["Accept"] = "*/*";
  // if not working remove return
  return request;
});

privateAPIClient.addResponseTransform(
  (response) => {
    // const navigate = useNavigate();

    if (response.status === 401) {
      cookies.remove("access_token");
      cookies.remove("refresh_token");
      localStorage.clear();
      window.location.href = "/login";
      // alert("Session is Expired");
    }
  },
  () => {
    console.log("<><><><><><><><><><><><><>");
  }
);

export default privateAPIClient;
