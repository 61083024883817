import React, { useState, useEffect, useContext } from "react";
import { Modal, Button } from "react-bootstrap";
import { APIDialler, APIgetDataSMSMail } from "../../../../../api/common";
import { useSearchParams } from "react-router-dom";
import CustomButton from "../../../../shared/CustomButton";
import GlobalContext from "../../../../../context/GlobalContext";

const CallSelectionModal = ({
  show,
  onClose,
  clickOnCall,
  CallID = () => {},
}) => {
  const [selectedNumber, setSelectedNumber] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const [searchParams] = useSearchParams();
  const productId = searchParams.get("productId");
  const [dataForSMSMail, setDataForSMSMail] = useState({});
  const { hasAccess } = useContext(GlobalContext);
  const leadId = searchParams.get("leadId");
  const [isDisabled, setIsDisabled] = useState(false);

  // Fetch data for SMS/Mail
  const getDataForMailSMS = async () => {
    const payload = { leadId: leadId };
    const res = await APIgetDataSMSMail(payload);
    if (res?.ok) {
      setDataForSMSMail(res?.data?.data);
    } else {
      setDataForSMSMail({});
    }
  };

  // Fetch data on component mount
  useEffect(() => {
    getDataForMailSMS();
  }, []);

  // Handle confirm button click
  const handleConfirm = async () => {
    if (!selectedNumber) {
      alert("Please select a number to call.");
      return;
    }
    setShowLoader(true);
    setIsDisabled(true);

    if (leadId) {
      const payload = {
        alternate_mobile: selectedNumber === "mobile" ? false : true,
      };

      const resp = await APIDialler(leadId, payload);
      if (resp?.status) {
        CallID(resp?.data?.call_id);
        // setIsDisabled(true);
      }
    }

    onClose();
    setShowLoader(false);
    clickOnCall();
  };

  // Extract phone numbers
  const mobileNumber = dataForSMSMail?.mobile_number;
  const alternateMobileNumber = dataForSMSMail?.alternate_mobile;

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Select Number to Call</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {mobileNumber && (
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              id="mobileNumber"
              checked={selectedNumber === "mobile"}
              onChange={() => setSelectedNumber("mobile")}
            />
            <label className="form-check-label" htmlFor="mobileNumber">
              {mobileNumber}
            </label>
          </div>
        )}
        {alternateMobileNumber && (
          <div className="form-check mt-3">
            <input
              className="form-check-input"
              type="radio"
              id="alternativeMobileNumber"
              checked={selectedNumber === "alternate"}
              onChange={() => setSelectedNumber("alternate")}
            />
            <label
              className="form-check-label"
              htmlFor="alternativeMobileNumber"
            >
              {alternateMobileNumber}
            </label>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
        {hasAccess("HAS_LM_INITITATE_CALL") && (
          <div className="lead-details-header d-flex align-items-center">
            <CustomButton
              className="initiate-call-btn bg-yellow text-blue fw-bold"
              onClick={handleConfirm}
              disabled={isDisabled}
            >
              <i className="bi bi-telephone me-2" />
              Call
            </CustomButton>
          </div>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default CallSelectionModal;
