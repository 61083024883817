import React, { useEffect, useState, useCallback } from "react";
import CustomInput from "../../../shared/CustomInput";
import CustomDropdown from "../../../shared/CustomDropdown";
import CustomButton from "../../../shared/CustomButton";
import {
  APIcreateLeadTaskList,
  APIgetLeadPolicyTypeDropDown,
  APIgetLeadTypeDropDown,
  APIgetLeadPlanTypeDropDown,
  APIgetLeadSourceDropDown,
} from "../../../../api/common";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import { displayToast } from "../../../shared/toast/toastUtils";

const AddLeadComponent = ({
  editLeadId,
  onCancel = () => {},
  onSave = () => {},
}) => {
  const [leadTypeId, setLeadTypeId] = useState([]);
  const [planTypeId, setPlanTypeId] = useState([]);
  const [policyType, setPolicyType] = useState([]);
  const [sourceType, setSourceType] = useState([]);
  const [selectedPlanTypeId, setSelectedPlanTypeId] = useState(null);
  const [selectedPolicy, setSelectedPolicy] = useState(null);
  const [selectedSource, setSelectedSource] = useState(null);
  const [selectedLeadTypeId, setSelectedLeadTypeId] = useState(null);

  const [error, setError] = useState("");

  // const {
  //   register,
  //   handleSubmit,
  //   control,
  //   formState: { errors },
  //   setValue,
  // } = useForm();

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({ reValidateMode: "onChange", mode: "onChange" });

  const [formData, setFormData] = useState({
    leadName: "",
    leadEmail: "",
    leadMobileNumber: "",
    status: 1,
    policyNumber: "POL123456789",
    registrationNumber: "",
  });

  const saveListner = async () => {
    const fields = [
      {
        key: "policyTypeId",
        value: selectedPolicy,
        errorMessage: "Please select a policy type.",
      },
      {
        key: "sourceTypeId",
        value: selectedSource,
        errorMessage: "Please select a lead source.",
      },
      {
        key: "planTypeId",
        value: selectedPlanTypeId,
        errorMessage: "Please select a plan type.",
      },
      {
        key: "leadTypeId",
        value: selectedLeadTypeId,
        errorMessage: "Please select a lead type.",
      },
    ];

    const newErrors = fields.reduce((acc, { key, value, errorMessage }) => {
      if (!value) {
        acc[key] = errorMessage;
      } else {
        formData[key] = value;
      }
      return acc;
    }, {});
    setError(newErrors);
    if (Object.keys(newErrors).length > 0) return;
    const result = await APIcreateLeadTaskList(formData);
    if (result?.ok) {
      onSave();
      displayToast({
        type: "success",
        title: result?.data?.message,
      });
    } else {
      displayToast({
        type: "error",
        title: result?.data?.message,
      });
    }
  };

  //--------------- useForm Logic -------------------

  const handleFormUpdate = (e) => {
    const { name, value } = e.target;
    console.log("test", name, value);
    console.log("typeof value", typeof Number(value));
    if (name === "leadMobileNumber") {
      if (/^\d*$/.test(value)) {
        // const vaildEmail = /^[6-9]\d{9}$/;
        // if (!vaildEmail.test(value)) {
        //   console.log("asdasdasdasd");
        //   setError((prevErrors) => ({
        //     ...prevErrors,
        //     [name]: "Please enter Vaild Mobile No.",
        //   }));
        // }
        setFormData((prev) => ({ ...prev, [name]: value }));
        setValue(name, value);
        setFormData((prev) => ({ ...prev, [name]: value }));
        setValue(name, value);
      }
    } else if (name !== "leadMobileNumber") {
      setFormData((prev) => ({ ...prev, [name]: value }));
      setValue(name, value);
      setFormData((prev) => ({ ...prev, [name]: value }));
      setValue(name, value);
    }
  };

  const formatRegistrationNumber = (e) => {
    let value = e.target.value.toUpperCase().replace(/[^A-Z0-9]/g, "");
    if (value.length > 2) value = value.slice(0, 2) + "-" + value.slice(2);
    if (value.length > 5) value = value.slice(0, 5) + "-" + value.slice(5);
    if (value.length > 8) value = value.slice(0, 8) + "-" + value.slice(8);

    setFormData((prev) => ({ ...prev, registrationNumber: value }));
  };

  const onSubmit = async (data) => {
    const payload = {
      leadName: data?.leadName || "",
      leadEmail: data?.leadEmail || "",
      leadMobileNumber: data?.leadMobileNumber || "",
      sourceTypeId: data?.sourceTypeId?.value || "",
      policyTypeId: data?.policyTypeId?.value || "",
      planTypeId: data?.planTypeId?.value || "",
      leadTypeId: data?.leadTypeId?.value || "",
      registrationNumber: data?.registrationNumber || "",
      status: 1,
    };
    const result = await APIcreateLeadTaskList(payload);
    if (result?.ok) {
      onSave();
      displayToast({
        type: "success",
        title: result?.data?.message,
      });
    } else {
      displayToast({
        type: "error",
        title: result?.data?.message,
      });
    }
    setFormData(data);
  };

  // const handleSelect = (selectedOption, { name }) => {
  //   const handlers = {
  //     sourceTypeId: setSelectedSource,
  //     policyTypeId: setSelectedPolicy,
  //     planTypeId: setSelectedPlanTypeId,
  //     leadTypeId: setSelectedLeadTypeId,
  //   };

  //   // Call the appropriate setter function
  //   handlers[name]?.(selectedOption?.value || null);

  //   // Set or clear the error message
  //   setError((prevErrors) => ({
  //     ...prevErrors,
  //     [name]: selectedOption ? "" : "Please select a value.",
  //   }));
  // };

  const fetchData = useCallback(
    async (apiFunction, setState, idKey = "id", nameKey = "name") => {
      try {
        const { data } = await apiFunction();
        if (data?.status && data?.data) {
          setState(
            data.data.map((item) => ({
              value: item[idKey],
              label: item[nameKey],
            }))
          );
        }
      } catch (error) {
        console.error(`Error fetching data from API:`, error);
      }
    },
    []
  );

  useEffect(() => {
    fetchData(APIgetLeadPolicyTypeDropDown, setPolicyType);
    fetchData(APIgetLeadPlanTypeDropDown, setPlanTypeId);
    fetchData(APIgetLeadTypeDropDown, setLeadTypeId);
    fetchData(
      APIgetLeadSourceDropDown,
      setSourceType,
      "lead_source_id",
      "source_name"
    );
  }, [fetchData]);

  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-6" style={{ padding: "5px 5px 15px" }}>
          <Controller
            control={control}
            name={"leadName"}
            rules={{
              required: {
                value: true,
                message: "This field is required to be filled*",
              },
              maxLength: {
                value: 25,
                message: `Max 25 characters allowed`,
              },
              pattern: {
                value: /^[a-zA-Z\s]+$/, // Regex for alphabets and spaces only
                message: "Only letters and spaces are allowed in the name",
              },
            }}
            render={({
              field: { onChange, onBlur, value },
              fieldState: { error },
            }) => (
              <div className="custom-input-wrapper">
                <label htmlFor="customInput" className="form-label">
                  {"Name"}
                </label>
                <span className="text-danger">*</span>
                <div className="input-with-prefix">
                  <input
                    type={"text"}
                    className="form-control form-control-lg"
                    placeholder={"Enter Name"}
                    value={value}
                    onChange={(e) => onChange(e)}
                    onBlur={onBlur}
                  />
                </div>
                {error && (
                  <div className="error-message-input">{error.message}</div>
                )}
              </div>
            )}
          />
        </div>

        <div className="col-sm-6" style={{ padding: "5px 5px 15px" }}>
          <Controller
            control={control}
            name={"policyTypeId"}
            rules={{
              required: {
                value: true,
                message: "This field is required",
              },
              // maxLength: {
              //   value: 10,
              //   message: `Max 10 characters allowed`,
              // },
              // pattern: {
              //   value: /^[6-9]\d{9}$/,
              //   message: "Please Enter Vailed Mobile No.",
              // },
            }}
            render={({
              field: { onChange, onBlur, value },
              fieldState: { error },
            }) => (
              <div className="custom-input-wrapper">
                <label htmlFor="customInput" className="form-label">
                  {"Policy Type"}
                </label>
                <span className="text-danger">*</span>
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  isClearable
                  value={value}
                  onChange={(e) => onChange(e)}
                  options={policyType}
                  placeholder={"Select Policy Type"}
                />
                {error && (
                  <div className="error-message-input">{error.message}</div>
                )}
              </div>
            )}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6" style={{ padding: "5px 5px 15px" }}>
          <Controller
            control={control}
            name={"leadMobileNumber"}
            rules={{
              required: {
                value: true,
                message: "This field is required to be filled*",
              },
              maxLength: {
                value: 10,
                message: `Max 10 characters allowed`,
              },
              pattern: {
                value: /^[6-9]\d{9}$/,
                message: "Please Enter Vailed Mobile No.",
              },
            }}
            render={({
              field: { onChange, onBlur, value },
              fieldState: { error },
            }) => (
              <div className="custom-input-wrapper">
                <label htmlFor="customInput" className="form-label">
                  {"Mobile No."}
                </label>
                <span className="text-danger">*</span>
                <div className="input-with-prefix">
                  <input
                    type={"text"}
                    className="form-control form-control-lg"
                    placeholder={"Enter Mobile Number"}
                    value={value}
                    onChange={(e) => {
                      const numericValue = e?.target?.value.replace(
                        /[^0-9]/g,
                        ""
                      );
                      if (numericValue.length <= 10) {
                        // handleFormUpdate(value);
                        onChange(numericValue);
                      }
                    }}
                    onBlur={onBlur}
                  />
                </div>
                {error && (
                  <div className="error-message-input">{error.message}</div>
                )}
              </div>
            )}
          />
        </div>
        <div className="col-sm-6" style={{ padding: "5px 5px 15px" }}>
          <Controller
            control={control}
            name={"leadEmail"}
            rules={{
              required: {
                value: true,
                message: "This field is required to be filled*",
              },
              pattern: {
                value:
                  /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.(com|in|org|co|net)$/,
                message: "Invalid Email Id format",
              },
            }}
            render={({
              field: { onChange, onBlur, value },
              fieldState: { error },
            }) => (
              <div className="custom-input-wrapper">
                <label htmlFor="customInput" className="form-label">
                  {"Email "}
                </label>
                <span className="text-danger">*</span>
                <div className="input-with-prefix">
                  <input
                    type={"text"}
                    className="form-control form-control-lg"
                    placeholder={"Enter Email"}
                    value={value}
                    onChange={(e) => {
                      // handleFormUpdate(value);
                      onChange(e);
                    }}
                    onBlur={onBlur}
                  />
                </div>

                {error && (
                  <div className="error-message-input">{error.message}</div>
                )}
              </div>
            )}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6" style={{ padding: "5px 5px 15px" }}>
          <Controller
            control={control}
            name={"sourceTypeId"}
            rules={{
              required: {
                value: true,
                message: "This field is required",
              },
              // maxLength: {
              //   value: 10,
              //   message: `Max 10 characters allowed`,
              // },
              // pattern: {
              //   value: /^[6-9]\d{9}$/,
              //   message: "Please Enter Vailed Mobile No.",
              // },
            }}
            render={({
              field: { onChange, onBlur, value },
              fieldState: { error },
            }) => (
              <div className="custom-input-wrapper">
                <label htmlFor="customInput" className="form-label">
                  {" Lead Source"}
                </label>
                <span className="text-danger">*</span>
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  isClearable
                  value={value}
                  onChange={(e) => onChange(e)}
                  options={sourceType}
                  placeholder={"Select Lead Source"}
                />
                {error && (
                  <div className="error-message-input">{error.message}</div>
                )}
              </div>
            )}
          />
        </div>

        <div className="col-sm-6" style={{ padding: "5px 5px 15px" }}>
          <Controller
            control={control}
            name={"registrationNumber"}
            rules={{
              required: {
                value: true,
                message: "This field is required to be filled*",
              },
              pattern: {
                value: /^[A-Z]{2}-\d{2}-[A-Z]{2}-\d{4}$/,
                message: "Invalid Registration Number format",
              },
            }}
            render={({
              field: { onChange, onBlur, value },
              fieldState: { error },
            }) => (
              <div className="custom-input-wrapper">
                <label htmlFor="customInput" className="form-label">
                  {"Registration Number "}
                </label>
                <span className="text-danger">*</span>
                <div className="input-with-prefix">
                  <input
                    type={"text"}
                    className="form-control form-control-lg"
                    placeholder={"Enter Registration No."}
                    value={value}
                    onChange={(e) => {
                      let value = e.target.value
                        .toUpperCase()
                        .replace(/[^A-Z0-9]/g, "");
                      if (value.length > 2)
                        value = value.slice(0, 2) + "-" + value.slice(2);
                      if (value.length > 5)
                        value = value.slice(0, 5) + "-" + value.slice(5);
                      if (value.length > 8)
                        value = value.slice(0, 8) + "-" + value.slice(8);
                      // handleFormUpdate(value);
                      if (value.length <= 13) {
                        onChange(value);
                      }
                    }}
                    onBlur={onBlur}
                  />
                </div>
                {error && (
                  <div className="error-message-input">{error.message}</div>
                )}
              </div>
            )}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6" style={{ padding: "5px 5px 15px" }}>
          <Controller
            control={control}
            name={"planTypeId"}
            rules={{
              required: {
                value: true,
                message: "This field is required",
              },
              // maxLength: {
              //   value: 10,
              //   message: `Max 10 characters allowed`,
              // },
              // pattern: {
              //   value: /^[6-9]\d{9}$/,
              //   message: "Please Enter Vailed Mobile No.",
              // },
            }}
            render={({
              field: { onChange, onBlur, value },
              fieldState: { error },
            }) => (
              <div className="custom-input-wrapper">
                <label htmlFor="customInput" className="form-label">
                  {"Plan Type"}
                </label>
                <span className="text-danger">*</span>
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  isClearable
                  value={value}
                  onChange={(e) => onChange(e)}
                  options={planTypeId}
                  placeholder={"Select Plan Type"}
                  menuPlacement="top"
                />
                {error && (
                  <div className="error-message-input">{error.message}</div>
                )}
              </div>
            )}
          />
        </div>

        <div className="col-sm-6" style={{ padding: "5px 5px 15px" }}>
          <Controller
            control={control}
            name={"leadTypeId"}
            rules={{
              required: {
                value: true,
                message: "This field is required",
              },
              // maxLength: {
              //   value: 10,
              //   message: `Max 10 characters allowed`,
              // },
              // pattern: {
              //   value: /^[6-9]\d{9}$/,
              //   message: "Please Enter Vailed Mobile No.",
              // },
            }}
            render={({
              field: { onChange, onBlur, value },
              fieldState: { error },
            }) => (
              <div className="custom-input-wrapper">
                <label htmlFor="customInput" className="form-label">
                  {"Lead Type"}
                </label>
                <span className="text-danger">*</span>
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  isClearable
                  value={value}
                  onChange={(e) => onChange(e)}
                  options={leadTypeId}
                  placeholder={"Select Lead Type"}
                  menuPlacement="top"
                />
                {error && (
                  <div className="error-message-input">{error.message}</div>
                )}
              </div>
            )}
          />
        </div>
      </div>
      <div className="form-button-main d-flex justify-content-end mt-3">
        <CustomButton
          className="view-template-btn mr-2 me-2 text-center d-flex align-items-center justify-content-center"
          onClick={onCancel}
        >
          <span className="view-btn ">Cancel</span>
        </CustomButton>
        <CustomButton
          className="report-download-btn mr-2 text-center d-flex align-items-center justify-content-center"
          onClick={handleSubmit(onSubmit)}
        >
          <span className="download-btn ">Add</span>
        </CustomButton>
      </div>
    </div>
  );
};

export default AddLeadComponent;
