import React, { useCallback, useEffect, useState } from "react";
import MainCard from "../../../../shared/MainCard";
import ROUTES_NAVIGATION from "../../../../../routes/routes";
import LastPolicyDetails from "../../AllLeadsComponent/EditLeadDetailsComponent/LastPolicyDetails";
import UpdatedLastPolicyDetails from "./UpdatedLastPolicyDetails";
import UpdatedAddonPACoverDetails from "./UpdatedAddonPACoverDetails";
import UpdatedVehicleDetails from "./UpdatedVehicleDetails";
import UpdatedOwnerDetails from "./UpdatedOwnerDetails";
import {
  APIForAproveReq,
  APIgetCopmareLeadInfo,
} from "../../../../../api/common";
import { useSearchParams } from "react-router-dom";
import { CleaningServices } from "@mui/icons-material";

const pagRoutes = [
  { path: "/", lebel: "Home" },
  {
    path: ROUTES_NAVIGATION.VEHICLE_INSURENCE_UPDATE_LEAD_INFO_DETAILS,
    lebel: "Home",
  },
];

const UpdateLeadInfoComponent = () => {
  const [searchParams] = useSearchParams();
  const leadId = searchParams.get("leadId");
  const productId = searchParams.get("productId");
const [data,setData]=useState()

  const getRequestedDatafromTC = useCallback(async () => {
    const payload = {
      lead_id: leadId,
    };
    const res = await APIgetCopmareLeadInfo(payload);

    console.log("compare res",res?.data?.data?.newUniqueObject)
    if(res.ok){
      setData(res?.data?.data?.newUniqueObject)
    }else{
      setData({})
    }
    // setPreValue(
    //   res?.data?.data?.newUniqueObject?.map((value) => value?.previous)
    // );

    // setUpdatedValue(
    //   res?.data?.data?.newUniqueObject?.map((value) => value?.new)
    // );
    // console.log(
    //   "resReques",
    //   res?.data?.data?.newUniqueObject?.map((value) => value?.previous)
    // );
  }, [leadId]);
  useEffect(() => {
    getRequestedDatafromTC();
  }, [getRequestedDatafromTC]);
  return (
    <MainCard
      title="Update Lead Info Details"
      isBack
      // pagRoutesP={pagRoutes}
    >
      <div>
        <label cla>Last policy Details</label>
        <UpdatedLastPolicyDetails
          // preValue={preValue}
          data={data}
        />
      </div>
      <div className="mt-4">
        <label>Add-On & PA Cover Details</label>

        <UpdatedAddonPACoverDetails
          data={data}
        />
      </div>
      <div className="mt-4">
        <label>Vehicle Details</label>
        <UpdatedVehicleDetails
          data={data}
        />
      </div>
      <div className="mt-4">
        <label>Owner Details</label>
        <UpdatedOwnerDetails  data={data} />
      </div>
    </MainCard>
  );
};

export default UpdateLeadInfoComponent;
