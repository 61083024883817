import React from "react";
import rightimg from "../../assets/img/photos/rightimg.png";
import "./RightSideContainer.scss";
const RightSideContainer = () => {
  return (
    <div className="col-md-6 d-flex align-items-center justify-content-center right-side">
      <div>
        <div className="right-side-container">
          <img
            src={rightimg}
            alt="Car Crash Illustration"
            className="img mb-4"
          />
        </div>
      </div>
    </div>
  );
};

export default RightSideContainer;
