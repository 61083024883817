import React, { useEffect, useState } from "react";
import CustomButton from "../../../../shared/CustomButton";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import CustomDateInput from "../../../../shared/CustomDateInput";
import { useDispatch, useSelector } from "react-redux";
import { mdiVanityLight } from "@mdi/js";
import { setFormManagerAsPerKey } from "../../../../../redux/FormData/reduxFormManage";

const AddOnAndPaCoverDetais = ({ addOnData }) => {
  const {
    control,
    // handleSubmit,
    formState: { errors },
    // setValue,
  } = useForm({ reValidateMode: "onChange", mode: "onChange" });
  const [passengerAssist, setPassangerAssist] = useState();
  const [consumableCover, setConsumableCover] = useState();
  const [roadSideAssist, setRoadSideAssist] = useState();
  const [depreciationCover, setDepreciationCover] = useState();
  const dispatch = useDispatch();

  console.log("addOnData", addOnData);
  useEffect(() => {
    if (addOnData) {
      setPassangerAssist(addOnData?.PassengerAssist);
      setConsumableCover(addOnData?.ConsumableCover);
      setRoadSideAssist(addOnData?.RoadsideAssistance);
      setDepreciationCover(addOnData?.DepreciationCover);
    }
  }, [addOnData]);

  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-6" style={{ padding: "5px 5px 15px" }}>
          <Controller
            control={control}
            defaultValue={passengerAssist}
            name={"leadName"}
            rules={{
              // required: {
              //   value: true,
              //   message: "This field is required to be filled*",
              // },
              maxLength: {
                value: 25,
                message: `Max 25 characters allowed`,
              },
            }}
            render={({
              field: { onChange, onBlur, value },
              fieldState: { error },
            }) => (
              <div className="custom-input-wrapper">
                <label htmlFor="customInput" className="form-label">
                  {"Passanger Assist"}
                </label>
                {/* <span className="text-danger">*</span> */}
                <div className="input-with-prefix">
                  <input
                    type={"text"}
                    className="form-control form-control-lg"
                    placeholder={"Enter Passanger Assist"}
                    value={passengerAssist}
                    // onChange={(e) => onChange(e)}
                    onChange={(e) => {
                      value = e.target.value;
                      const onlyNumbers = value.replace(/[^0-9]/g, "");
                      // setPassangerAssist(onlyNumbers);
                      dispatch(
                        setFormManagerAsPerKey({
                          type: "Add_ON_PA_Cover",
                          key: "PassengerAssist",
                          value: onlyNumbers,
                        })
                      );
                    }}
                    onBlur={onBlur}
                  />
                </div>
                {error && (
                  <div className="error-message-input">{error.message}</div>
                )}
              </div>
            )}
          />
        </div>

        <div className="col-sm-6" style={{ padding: "5px 5px 15px" }}>
          <Controller
            control={control}
            defaultValue={consumableCover}
            name={"consumableCover"}
            rules={{
              // required: {
              //   value: true,
              //   message: "This field is required to be filled*",
              // },
              maxLength: {
                value: 25,
                message: `Max 25 characters allowed`,
              },
            }}
            render={({
              field: { onChange, onBlur, value },
              fieldState: { error },
            }) => (
              <div className="custom-input-wrapper">
                <label htmlFor="customInput" className="form-label">
                  {"Consumable Cover"}
                </label>
                {/* <span className="text-danger">*</span> */}
                <div className="input-with-prefix">
                  <input
                    type={"text"}
                    className="form-control form-control-lg"
                    placeholder={"Enter Consumable Cover"}
                    value={consumableCover}
                    // onChange={(e) => onChange(e)}
                    onChange={(e) => {
                      value = e.target.value;
                      const onlyNumbers = value.replace(/[^0-9]/g, "");
                      // setConsumableCover(onlyNumbers);
                      dispatch(
                        setFormManagerAsPerKey({
                          type: "Add_ON_PA_Cover",
                          key: "ConsumableCover",
                          value: onlyNumbers,
                        })
                      );
                    }}
                    onBlur={onBlur}
                  />
                </div>
                {error && (
                  <div className="error-message-input">{error.message}</div>
                )}
              </div>
            )}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-sm-6" style={{ padding: "5px 5px 15px" }}>
          <Controller
            control={control}
            defaultValue={roadSideAssist}
            name={"RoadsideAssistance"}
            rules={{
              // required: {
              //   value: true,
              //   message: "This field is required to be filled*",
              // },
              maxLength: {
                value: 25,
                message: `Max 25 characters allowed`,
              },
            }}
            render={({
              field: { onChange, onBlur, value },
              fieldState: { error },
            }) => (
              <div className="custom-input-wrapper">
                <label htmlFor="customInput" className="form-label">
                  {"Roadside-assistance"}
                </label>
                {/* <span className="text-danger">*</span> */}
                <div className="input-with-prefix">
                  <input
                    type={"text"}
                    className="form-control form-control-lg"
                    placeholder={"Enter Roadside-assistance"}
                    value={roadSideAssist}
                    // onChange={(e) => onChange(e)}
                    onChange={(e) => {
                      value = e.target.value;
                      const onlyNumbers = value.replace(/[^0-9]/g, "");
                      // setRoadSideAssist(onlyNumbers);
                      dispatch(
                        setFormManagerAsPerKey({
                          type: "Add_ON_PA_Cover",
                          key: "RoadsideAssistance",
                          value: onlyNumbers,
                        })
                      );
                    }}
                    onBlur={onBlur}
                  />
                </div>
                {error && (
                  <div className="error-message-input">{error.message}</div>
                )}
              </div>
            )}
          />
        </div>

        <div className="col-sm-6" style={{ padding: "5px 5px 15px" }}>
          <Controller
            control={control}
            defaultValue={depreciationCover}
            name={"leadName"}
            rules={{
              required: {
                value: true,
                message: "This field is required to be filled*",
              },
              maxLength: {
                value: 25,
                message: `Max 25 characters allowed`,
              },
            }}
            render={({
              field: { onChange, onBlur, value },
              fieldState: { error },
            }) => (
              <div className="custom-input-wrapper">
                <label htmlFor="customInput" className="form-label">
                  {"Depreciation cover"}
                </label>
                {/* <span className="text-danger">*</span> */}
                <div className="input-with-prefix">
                  <input
                    type={"text"}
                    className="form-control form-control-lg"
                    placeholder={"Enter Depreciation cover"}
                    value={depreciationCover}
                    // onChange={(e) => onChange(e)}
                    onChange={(e) => {
                      value = e.target.value;
                      const onlyNumbers = value.replace(/[^0-9]/g, "");
                      // setDepreciationCover(onlyNumbers);
                      dispatch(
                        setFormManagerAsPerKey({
                          type: "Add_ON_PA_Cover",
                          key: "DepreciationCover",
                          value: onlyNumbers,
                        })
                      );
                    }}
                    onBlur={onBlur}
                  />
                </div>
                {error && (
                  <div className="error-message-input">{error.message}</div>
                )}
              </div>
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default AddOnAndPaCoverDetais;
