import React, { useCallback, useContext, useEffect, useState } from "react";
import CustomTab from "../../../../shared/CustomTab";
import LeadManagmentCallHistoryTableList from "./LeadManagmentCallHistoryTableList";
import "./LeadManagmentCallStatus.scss";
import LeadManagmentCallStatusTableList from "./LeadManagmentCallStatusTableList";
import { CALL_STATUS_DATA } from "./defaultData";
import LeadManagmentClaimTableList from "./LeadManagmentClaimTableList";
import { useNavigate, useSearchParams } from "react-router-dom";
import ROUTES_NAVIGATION from "../../../../../routes/routes";
import {
  APIgetDispositionLead,
  APIPolicyStatus,
} from "../../../../../api/common";
const TAB_LIST = [
  { id: "callhistory", label: "Call History", icon: " bi-telephone" },
  { id: "statusdetails", label: "Status Details", icon: "bi-card-text" },
];
const LeadManagmentCallStatusComponent = ({
  trigger = () => {},
  showCallDetails,
  handleSaveClick = () => {},
  funcForHistory,
  disableFuncForHiatory = () => {},
}) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const productId = searchParams.get("productId");
  const leadId = searchParams.get("leadId");
  const [activeTab, setActiveTab] = useState(TAB_LIST[0]);
  const [tableLoaderShow, setTableLoaderShow] = useState(false);
  const [callStatusTableList, setCallStatusTableList] = useState([]);
  const [leadPolicyStatus, setLeadPolicyStatus] = useState([]);
  // const [funcForHistory, setFuncHistory] = useState(false);

  const getCallDispositionHandler = useCallback(async () => {
    const payload = {
      Id: productId,
    };
    const resp = await APIgetDispositionLead(payload);

    if (resp?.ok) {
      setCallStatusTableList(resp?.data?.data?.mainData);
    } else {
      setCallStatusTableList([]);
    }
  }, [productId]);

  const getStatusList = useCallback(async (productId) => {
    const resp = await APIPolicyStatus(productId);
    if (resp?.ok) {
      setLeadPolicyStatus(resp?.data?.data);
    } else {
      setLeadPolicyStatus([]);
    }
  }, []);

  // if (funcForHistory) {

  // }

  // useEffect(() => {
  //   if (activeTab?.id === "callhistory") {
  //     if (!showCallDetails) getCallDispositionHandler(leadId);
  //   } else if (activeTab?.id === "statusdetails") {
  //     getStatusList(leadId);
  //   }
  // }, [
  //   activeTab?.id,
  //   getCallDispositionHandler,
  //   getStatusList,
  //   leadId,
  //   productId,
  //   showCallDetails,
  // ]);

  useEffect(() => {
    if (activeTab?.id === "callhistory" || showCallDetails) {
      getCallDispositionHandler(leadId);
    }

    if (activeTab?.id === "statusdetails") {
      getStatusList(leadId);
    }
  }, [
    activeTab?.id,
    getCallDispositionHandler,
    getStatusList,
    leadId,
    productId,
    showCallDetails,
  ]);

  return (
    <div>
      <div className="">
        <CustomTab
          tabList={TAB_LIST}
          onClick={(tab) => {
            setActiveTab(tab);
          }}
          style={{ width: "8rem" }}
          activeTab={activeTab}
        />
        {activeTab?.id === "callhistory" && (
          <LeadManagmentCallHistoryTableList
            tableRow={callStatusTableList}
            tableLoaderShow={tableLoaderShow}
            onPlay={() => {}}
            onDownload={() => {}}
          />
        )}
        {activeTab?.id === "statusdetails" && (
          <LeadManagmentCallStatusTableList
            tableRow={leadPolicyStatus}
            tableLoaderShow={tableLoaderShow}
            onReferanceClick={(row) => {
              if (row.status === "Policy Done") {
                navigate(
                  ROUTES_NAVIGATION.VEHICLE_INSURENCE_POLICY_DONE_LEAD +
                    "?id=" +
                    row?.id
                );
              }
              if (row.status === "Proposal") {
                navigate(
                  ROUTES_NAVIGATION.VEHICLE_INSURENCE_PROPOSAL_LEAD +
                    "?id=" +
                    row?.id
                );
              }
              if (row.status === "Quotation") {
                navigate(
                  ROUTES_NAVIGATION.VEHICLE_INSURENCE_QUOTATION_SEND +
                    "?id=" +
                    row?.id
                );
              }
            }}
          />
        )}
        {activeTab?.id === "claim" && (
          <LeadManagmentClaimTableList
            tableRow={CALL_STATUS_DATA}
            tableLoaderShow={tableLoaderShow}
            onProposalClick={(row) => {
              navigate(
                ROUTES_NAVIGATION.VEHICLE_INSURENCE_PROPOSAL_LEAD +
                  "?id=" +
                  row?.id
              );
            }}
          />
        )}
      </div>
    </div>
  );
};

export default LeadManagmentCallStatusComponent;
