import React from "react";
import CustomInput from "../../../../shared/CustomInput";

const UpdatedOwnerDetails = ({data}) => {
  return (
    <div className="mt-2">
      <div className="row">
        <div className="col-md-3">
          <div className="card">
            <div className="row m-2">
              <label>Previous Full Name</label>
              <CustomInput value={data?.prev_lead_name} disabled />
            </div>
            <div className="row m-2">
              <label>Updated Full Name</label>
              <CustomInput value={data?.updated_lead_name} disabled />
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="card">
            <div className="card">
              <div className="row m-2">
                <label>Previous Customer ID</label>
                <CustomInput value={data?.updated_lead_name} disabled />
              </div>
              <div className="row m-2">
                <label>Updated Customer ID</label>
                <CustomInput value={data?.updated_lead_name} disabled />
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="card">
            <div className="card">
              <div className="row m-2">
                <label>Previous DOB</label>
                <CustomInput value={data?.prev_DOB} disabled />
              </div>
              <div className="row m-2">
                <label>Updated DOB</label>
                <CustomInput value={data?.updated_DOB} disabled />
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="card">
            <div className="card">
              <div className="row m-2">
                <label>Previous Mobile number</label>
                <CustomInput value={data?.prev_lead_mobile_number} disabled />
              </div>
              <div className="row m-2">
                <label>Updated Mobile number</label>
                <CustomInput value={data?.updated_lead_mobile_number}  disabled />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-md-3">
          <div className="card">
            <div className="row m-2">
              <label>Previous Email id</label>
              <CustomInput value={data?.prev_lead_email} disabled />
            </div>
            <div className="row m-2">
              <label>Updated Email id</label>
              <CustomInput value={data?.updated_lead_email} disabled />
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="card">
            <div className="row m-2">
              <label>Previous Address</label>
              <CustomInput  value={data?.prev_address} disabled />
            </div>
            <div className="row m-2">
              <label>Updated Address</label>
              <CustomInput value={data?.updated_address} disabled />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdatedOwnerDetails;
