import React from "react";
import OTPComponent from "../../components/pages/VehicleInsurenceComponent/OTPComponent";

const OtpPage = () => {
  return (
    <>
      <OTPComponent />
    </>
  );
};

export default OtpPage;
