import React, { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import CustomButton from "../../../../shared/CustomButton";
import Select from "react-select";
import CustomDateInput from "../../../../shared/CustomDateInput";
import { useDispatch, useSelector } from "react-redux";
import {
  manufacturerId,
  setFormManagerAsPerKey,
} from "../../../../../redux/FormData/reduxFormManage";
import {
  APIgetManufaturerOption,
  APIgetModelOption,
  APIgetVariant,
} from "../../../../../api/common";
import moment from "moment";

const VehicleDetails = ({ manufacturerOption, VehicleData }) => {
  const [manufaucturerOption, setManufacturerOption] = useState();
  const [manufaucturer, setManufacturer] = useState();
  const [modelOPtion, setModelOption] = useState();
  const [model, setModel] = useState();
  const [variantOption, setVariantOPtion] = useState();
  const [variant, setVariant] = useState();
  const [salesDate, setSalesDate] = useState(null);
  const dispatch = useDispatch();
  const {
    control,
    // handleSubmit,
    formState: { errors },
    // setValue,
  } = useForm({ reValidateMode: "onChange", mode: "onChange" });

  const vehicleEditData = useSelector(
    (state) => state?.reduxFormManage?.leadDetails?.Vehicle_Details
  );

  const RtoOption = useSelector((state) => state?.reduxFormManage?.rtoData);

  const getManufaturerOption = async () => {
    const res = await APIgetManufaturerOption();
    if (res?.ok) {
      setManufacturerOption(res?.data?.data);
    } else {
      setManufacturerOption([]);
    }
  };

  const getModelOption = useCallback(async () => {
    const payload = {
      id: manufaucturer?.value,
    };
    const res = await APIgetModelOption(payload);
    if (res?.ok) {
      setModelOption(res?.data?.data);
    } else {
      setModelOption([]);
    }
  }, [manufaucturer?.value]);

  const getVariantOption = useCallback(async () => {
    const payload = {
      id: model?.value,
    };
    const res = await APIgetVariant(payload);
    if (res?.ok) {
      setVariantOPtion(res?.data?.data);
    } else {
      setVariantOPtion([]);
    }
  }, [model?.value]);

  //   rto_code(pin):"an01"
  // rto_label(pin):"an-01"
  // rto_city(pin):"port blair"

  useEffect(() => {
    if (VehicleData) {
      setManufacturer(VehicleData?.Manufacturer);
      setModel(VehicleData?.Model);
      setVariant(VehicleData?.Variant);
    }
  }, [VehicleData]);
  useEffect(() => {
    getManufaturerOption();
  }, []);

  useEffect(() => {
    if (manufaucturer?.value) {
      getModelOption();
    }
  }, [getModelOption, manufaucturer?.value]);

  useEffect(() => {
    if (model?.value) {
      getVariantOption();
    }
  }, [getVariantOption, model?.value]);

  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-6" style={{ padding: "5px 5px 15px" }}>
          <Controller
            control={control}
            defaultValue={vehicleEditData?.Manufacturer}
            name={"manufacturer"}
            rules={{
              required: {
                value: true,
                message: "This field is required",
              },
              // maxLength: {
              //   value: 10,
              //   message: `Max 10 characters allowed`,
              // },
              // pattern: {
              //   value: /^[6-9]\d{9}$/,
              //   message: "Please Enter Vailed Mobile No.",
              // },
            }}
            render={({
              field: { onChange, onBlur, value },
              fieldState: { error },
            }) => (
              <div className="custom-input-wrapper">
                <label htmlFor="customInput" className="form-label">
                  {"Manufacturer"}
                </label>
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  isClearable
                  value={value}
                  onChange={(e) => {
                    onChange(e);
                    // setManufacturer(e);
                    dispatch(
                      setFormManagerAsPerKey({
                        type: "Vehicle_Details",
                        key: "Manufacturer",
                        value: e,
                      })
                    );
                  }}
                  options={
                    manufaucturerOption &&
                    manufaucturerOption.map((items) => ({
                      label: items?.make,
                      value: items?.manufacturer_id,
                    }))
                  }
                  placeholder={"Select Manufacturer"}
                />
                {error && (
                  <div className="error-message-input">{error.message}</div>
                )}
              </div>
            )}
          />
        </div>

        <div className="col-sm-6" style={{ padding: "5px 5px 15px" }}>
          <Controller
            control={control}
            name={"policyTypeId"}
            rules={{
              required: {
                value: true,
                message: "This field is required",
              },
              // maxLength: {
              //   value: 10,
              //   message: `Max 10 characters allowed`,
              // },
              // pattern: {
              //   value: /^[6-9]\d{9}$/,
              //   message: "Please Enter Vailed Mobile No.",
              // },
            }}
            render={({
              field: { onChange, onBlur, value },
              fieldState: { error },
            }) => (
              <div className="custom-input-wrapper">
                <label htmlFor="customInput" className="form-label">
                  {"Model"}
                </label>
                {/* <span className="text-danger">*</span> */}
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  isClearable
                  value={model}
                  onChange={(e) => {
                    onChange(e);
                    // setModel(e);
                    dispatch(
                      setFormManagerAsPerKey({
                        type: "Vehicle_Details",
                        key: "Model",
                        value: e,
                      })
                    );
                  }}
                  options={
                    modelOPtion &&
                    modelOPtion.map((item) => ({
                      label: item?.model,
                      value: item?.model_id,
                    }))
                  }
                  placeholder={"Select Model"}
                />
                {error && (
                  <div className="error-message-input">{error.message}</div>
                )}
              </div>
            )}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-sm-6" style={{ padding: "5px 5px 15px" }}>
          <Controller
            control={control}
            defaultValue={manufaucturer}
            name={"variant"}
            rules={{
              required: {
                value: true,
                message: "This field is required",
              },
              // maxLength: {
              //   value: 10,
              //   message: `Max 10 characters allowed`,
              // },
              // pattern: {
              //   value: /^[6-9]\d{9}$/,
              //   message: "Please Enter Vailed Mobile No.",
              // },
            }}
            render={({
              field: { onChange, onBlur, value },
              fieldState: { error },
            }) => (
              <div className="custom-input-wrapper">
                <label htmlFor="customInput" className="form-label">
                  {"Variant"}
                </label>
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  isClearable
                  value={variant}
                  onChange={(e) => {
                    onChange(e);
                    // setVariant(e);
                    dispatch(
                      setFormManagerAsPerKey({
                        type: "Vehicle_Details",
                        key: "Variant",
                        value: e,
                      })
                    );
                  }}
                  options={
                    variantOption &&
                    variantOption.map((items) => ({
                      label: items?.variant,
                      value: items?.variant_id,
                    }))
                  }
                  placeholder={"Select variant"}
                />
                {error && (
                  <div className="error-message-input">{error.message}</div>
                )}
              </div>
            )}
          />
        </div>

        <div className="col-sm-6" style={{ padding: "5px 5px 15px" }}>
          <Controller
            control={control}
            name={"regNumber"}
            rules={{
              required: {
                value: true,
                message: "This field is required to be filled*",
              },
              maxLength: {
                value: 25,
                message: `Max 25 characters allowed`,
              },
            }}
            render={({
              field: { onChange, onBlur, value },
              fieldState: { error },
            }) => (
              <div className="custom-input-wrapper">
                <label htmlFor="customInput" className="form-label">
                  {"Registration number"}
                </label>
                <div className="input-with-prefix">
                  <input
                    type={"text"}
                    className="form-control form-control-lg"
                    placeholder={"Enter Registration number"}
                    value={vehicleEditData?.RegistrationNumber}
                    onChange={(e) => onChange(e)}
                    onBlur={onBlur}
                    disabled
                  />
                </div>
                {error && (
                  <div className="error-message-input">{error.message}</div>
                )}
              </div>
            )}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-sm-6" style={{ padding: "5px 5px 15px" }}>
          <Controller
            control={control}
            name={"ChassisNumber"}
            rules={{
              required: {
                value: true,
                message: "This field is required to be filled*",
              },
              maxLength: {
                value: 25,
                message: `Max 25 characters allowed`,
              },
            }}
            render={({
              field: { onChange, onBlur, value },
              fieldState: { error },
            }) => (
              <div className="custom-input-wrapper">
                <label htmlFor="customInput" className="form-label">
                  {"Chassis Number"}
                </label>
                <div className="input-with-prefix">
                  <input
                    type={"text"}
                    className="form-control form-control-lg"
                    placeholder={"Enter Chassis Number "}
                    value={vehicleEditData?.ChassisNumber}
                    // onChange={(e) => onChange(e)}
                    onBlur={onBlur}
                    disabled
                  />
                </div>
                {error && (
                  <div className="error-message-input">{error.message}</div>
                )}
              </div>
            )}
          />
        </div>

        <div className="col-sm-6" style={{ padding: "5px 5px 15px" }}>
          <Controller
            control={control}
            name={"enginNumber"}
            rules={{
              required: {
                value: true,
                message: "This field is required to be filled*",
              },
              maxLength: {
                value: 25,
                message: `Max 25 characters allowed`,
              },
            }}
            render={({
              field: { onChange, onBlur, value },
              fieldState: { error },
            }) => (
              <div className="custom-input-wrapper">
                <label htmlFor="customInput" className="form-label">
                  {"Engine Number"}
                </label>
                <div className="input-with-prefix">
                  <input
                    type={"text"}
                    className="form-control form-control-lg"
                    placeholder={"Enter Engine number"}
                    value={vehicleEditData?.EngineNumber}
                    onChange={(e) => onChange(e)}
                    onBlur={onBlur}
                    disabled
                  />
                </div>
                {error && (
                  <div className="error-message-input">{error.message}</div>
                )}
              </div>
            )}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-sm-6" style={{ padding: "5px 5px 15px" }}>
          <Controller
            control={control}
            defaultValue={vehicleEditData?.RTO}
            name={"rto"}
            rules={{
              required: {
                value: true,
                message: "This field is required",
              },
            }}
            render={({
              field: { onChange, onBlur, value },
              fieldState: { error },
            }) => (
              <div className="custom-input-wrapper">
                <label htmlFor="customInput" className="form-label">
                  {"RTO"}
                </label>
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  isClearable
                  value={value}
                  onChange={(e, value) => {
                    onChange(e);
                    dispatch(
                      setFormManagerAsPerKey({
                        type: "Vehicle_Details",
                        key: "RTO",
                        value: e,
                      })
                    );
                  }}
                  options={
                    RtoOption &&
                    RtoOption.map((itmes) => ({
                      label: itmes?.rto_label,
                      value: itmes?.id,
                    }))
                  }
                  placeholder={"Select RTO"}
                />
                {error && (
                  <div className="error-message-input">{error.message}</div>
                )}
              </div>
            )}
          />
        </div>

        <div className="col-sm-6" style={{ padding: "5px 5px 15px" }}>
          <Controller
            control={control}
            defaultValue={vehicleEditData?.Colour}
            name={"color"}
            rules={{
              required: {
                value: true,
                message: "This field is required to be filled*",
              },
              maxLength: {
                value: 25,
                message: `Max 25 characters allowed`,
              },
            }}
            render={({
              field: { onChange, onBlur, value },
              fieldState: { error },
            }) => (
              <div className="custom-input-wrapper">
                <label htmlFor="customInput" className="form-label">
                  {"Color"}
                </label>
                <div className="input-with-prefix">
                  <input
                    type={"text"}
                    className="form-control form-control-lg"
                    placeholder={"Enter Color"}
                    value={VehicleData?.Colour}
                    onChange={(e) => {
                      //  onChange(e)

                      dispatch(
                        setFormManagerAsPerKey({
                          type: "Vehicle_Details",
                          key: "Colour",
                          value: e.target.value,
                        })
                      );
                    }}
                    onBlur={onBlur}
                  />
                </div>
                {error && (
                  <div className="error-message-input">{error.message}</div>
                )}
              </div>
            )}
          />
        </div>
        <div className="col-sm-6" style={{ padding: "5px 5px 15px" }}>
          <Controller
            control={control}
            defaultValue={vehicleEditData?.FuelType}
            name={"fule_type"}
            rules={{
              required: {
                value: true,
                message: "This field is required to be filled*",
              },
              maxLength: {
                value: 25,
                message: `Max 25 characters allowed`,
              },
            }}
            render={({
              field: { onChange, onBlur, value },
              fieldState: { error },
            }) => (
              <div className="custom-input-wrapper">
                <label htmlFor="customInput" className="form-label">
                  {"Fule Type"}
                </label>
                <div className="input-with-prefix">
                  <input
                    type={"text"}
                    className="form-control form-control-lg"
                    placeholder={"Enter FuleType"}
                    value={vehicleEditData?.FuelType}
                    onChange={(e) => {
                      //  onChange(e)

                      dispatch(
                        setFormManagerAsPerKey({
                          type: "Vehicle_Details",
                          key: "FuleType",
                          value: e.target.value,
                        })
                      );
                    }}
                    onBlur={onBlur}
                  />
                </div>
                {error && (
                  <div className="error-message-input">{error.message}</div>
                )}
              </div>
            )}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6" style={{ padding: "5px 5px 15px" }}>
          <Controller
            control={control}
            // defaultValue={vehicleEditData?.SoldBy}
            name={"soldBy"}
            rules={{
              required: {
                value: true,
                message: "This field is required to be filled*",
              },
              maxLength: {
                value: 25,
                message: `Max 25 characters allowed`,
              },
            }}
            render={({
              field: { onChange, onBlur, value },
              fieldState: { error },
            }) => (
              <div className="custom-input-wrapper">
                <label htmlFor="customInput" className="form-label">
                  {"Sold by"}
                </label>
                <div className="input-with-prefix">
                  <input
                    type={"text"}
                    className="form-control form-control-lg"
                    placeholder={"Enter Sold by"}
                    value={vehicleEditData?.SoldBy}
                    // value={value}
                    onChange={(e) => {
                      //  onChange(e)
                      dispatch(
                        setFormManagerAsPerKey({
                          type: "Vehicle_Details",
                          key: "SoldBy",
                          value: e.target.value,
                        })
                      );
                    }}
                    onBlur={onBlur}
                  />
                </div>
                {error && (
                  <div className="error-message-input">{error.message}</div>
                )}
              </div>
            )}
          />
        </div>

        {/* --------manuFacturingYear---------- */}
        <div className="col-sm-6" style={{ padding: "5px 5px 15px" }}>
          <Controller
            control={control}
            defaultValue={vehicleEditData?.ManufacturingYear}
            name={"manuFacturingYear"}
            // rules={{
            //   required: {
            //     value: true,
            //     message: "This field is required to be filled*",
            //   },
            //   maxLength: {
            //     value: 10,
            //     message: `Max 10 characters allowed`,
            //   },
            //   pattern: {
            //     value: /^[6-9]\d{9}$/,
            //     message: "Please Enter Vailed Mobile No.",
            //   },
            // }}
            render={({
              field: { onChange, onBlur, value },
              fieldState: { error },
            }) => (
              <div className="custom-input-wrapper">
                <label htmlFor="customInput" className="form-label">
                  {"Manufacturing year"}
                </label>
                {/* <span className="text-danger">*</span> */}
                <div className="input-with-prefix">
                  <CustomDateInput
                    // label="From"
                    placeholderText="DD/MM/YYYY"
                    isDateRange={false}
                    className="from-date-input ms-1"
                    selected={vehicleEditData?.ManufacturingYear}
                    // selected={value}
                    onChange={(date) => {
                      // setStartDate(date || null);
                      dispatch(
                        setFormManagerAsPerKey({
                          type: "Vehicle_Details",
                          key: "ManufacturingYear",
                          value: moment(date, [
                            "YYYY-MM-DD",
                            "YYYY-DD-MM",
                            "DD-MM-YYYY",
                          ]).format("yyyy-MM-DD"),
                        })
                      );
                    }}
                  />
                </div>
                {error && (
                  <div className="error-message-input">{error.message}</div>
                )}
              </div>
            )}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6" style={{ padding: "5px 5px 15px" }}>
          <Controller
            control={control}
            defaultValue={vehicleEditData?.SaleDate}
            name={"Sale date"}
            // rules={{
            //   required: {
            //     value: true,
            //     message: "This field is required to be filled*",
            //   },
            //   pattern: {
            //     value:
            //       /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.(com|in|org|co|net)$/,
            //     message: "Invalid Email Id format",
            //   },
            // }}
            render={({
              field: { onChange, onBlur, value },
              fieldState: { error },
            }) => (
              <div className="custom-input-wrapper">
                <label htmlFor="customInput" className="form-label">
                  {"Sales date"}
                </label>
                <div className="input-with-prefix">
                  <CustomDateInput
                    // label="From"
                    placeholderText="DD/MM/YYYY"
                    isDateRange={false}
                    className="from-date-input ms-1"
                    selected={moment(vehicleEditData?.SaleDate, [
                      "YYYY-MM-DD",
                      "YYYY-DD-MM",
                      "DD-MM-YYYY",
                    ]).format("yyyy-MM-DD")}
                    // selected={vehicleEditData?.SaleDate}
                    onChange={(date) => {
                      // setSalesDate(date || null);
                      dispatch(
                        setFormManagerAsPerKey({
                          type: "Vehicle_Details",
                          key: "SaleDate",
                          value: moment(date, [
                            "YYYY-MM-DD",
                            "YYYY-DD-MM",
                            "DD-MM-YYYY",
                          ]).format("yyyy-MM-DD"),
                        })
                      );
                    }}
                  />
                </div>

                {error && (
                  <div className="error-message-input">{error.message}</div>
                )}
              </div>
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default VehicleDetails;
