import React, { useState } from "react";
import RightSideContainer from "../../../../pages/RightSideContainer";
import { useNavigate } from "react-router-dom";
import "./ForgotPass.scss";
import quantique from "../../../../assets/img/photos/quantique.svg";
import { displayToast } from "../../../shared/toast/toastUtils";
import { APIForgotPassword } from "../../../../api/common";

const ForgotPasswordComponent = () => {
  const [userName, setUserName] = useState("");
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const validateForm = () => {
    const validationErrors = {};
    if (!userName.trim()) {
      validationErrors.emailError = "Email ID is required";
    } else if (!emailRegex.test(userName.trim())) {
      validationErrors.emailError = "Invalid Email format";
    }
    setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (validateForm()) {
      setLoading(true);
      try {
        const response = await APIForgotPassword({ email: userName.trim() });
        if (response?.status === 200) {
          displayToast({
            type: "success",
            title: "OTP sent successfully. Please check your email.",
          });
          navigate("/otp", { state: { email: userName.trim() } });
        } else {
          displayToast({
            type: "error",
            title: response.data?.message || "Failed to send OTP.",
          });
        }
      } catch (error) {
        console.error("Error sending OTP:", error);
        displayToast({
          type: "error",
          title: error.response?.data?.message || "Something went wrong!",
        });
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <RightSideContainer />
        <div
          className="col-md-6 d-flex align-items-center justify-content-center"
          style={{ backgroundColor: "white" }}
        >
          <div className="forgot-pass-box">
            <div className="mb-5">
              <img src={quantique} alt="Quantique Logo" className="img-fluid" />
            </div>
            <h4 className="mb-4 text-black">
              Forgot Password? That’s OK! <br />
              Let’s change it.
            </h4>
            <form>
              <div className="form-group mb-4">
                <label className="font-8 text-gray">Confirm Login ID</label>
                <input
                  type="text"
                  className="form-control mt-2 text-left"
                  id="loginid"
                  placeholder="abcXXXXXXXd123@gmail.com"
                  value={userName}
                  onChange={(e) => setUserName(e.target.value)}
                />
                {errors.emailError && (
                  <div className="error-message text-danger mt-1">
                    {errors.emailError}
                  </div>
                )}
              </div>
              <div className="justify-content-between mb-2 text-left">
                <div
                  className="text-primary"
                  style={{ cursor: "pointer", textAlign: "end" }}
                  onClick={() => navigate("/login")}
                >
                  Back to login
                </div>
              </div>
            </form>
            <button
              className="col-6 btn btn-block login-button"
              style={{
                backgroundColor: "#4285F4",
                color: "white",
                borderRadius: "10px",
                width: "100%",
              }}
              onClick={handleSubmit}
              disabled={loading || !userName || errors.emailError}
            >
              {loading ? "Sending OTP..." : "Send OTP"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordComponent;
