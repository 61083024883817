import React from "react";
import LastPolicyDetails from "./LastPolicyDetails";
import AddOnAndPaCoverDetais from "./AddOnAndPaCoverDetais";
import VehicleDetails from "./VehicleDetails";
import OwnerDetails from "./OwnerDetails";
import { useSelector } from "react-redux";

const EditLeadForm = ({ selectedForm, setSelectedProduct }) => {
  const lastPolicyDetailsData = useSelector(
    (state) => state?.reduxFormManage?.leadDetails?.Last_Policy_Details
  );
  console.log("index", lastPolicyDetailsData);
  const addOnData = useSelector(
    (state) => state?.reduxFormManage?.leadDetails?.Add_ON_PA_Cover
  );

  const manufacturerOption = useSelector(
    (state) => state?.reduxFormManage?.manufacturerOption
  );
  const ownerDetails = useSelector(
    (state) => state?.reduxFormManage?.leadDetails?.Owner_Details
  );
  const VehicleData = useSelector(
    (state) => state?.reduxFormManage?.leadDetails?.Vehicle_Details
  );
  console.log("VehicleData", VehicleData);

  const formFieldMapping = {
    last: (
      <LastPolicyDetails
        selectedDetails={selectedForm}
        setSelectedProduct={setSelectedProduct}
        lastPolicyDetailsData={lastPolicyDetailsData}
      />
    ),
    add: (
      <AddOnAndPaCoverDetais
        selectedDetails={selectedForm}
        setSelectedProduct={setSelectedProduct}
        addOnData={addOnData}
      />
    ),
    vehicle: (
      <VehicleDetails
        selectedDetails={selectedForm}
        setSelectedProduct={setSelectedProduct}
        manufacturerOption={manufacturerOption}
        VehicleData={VehicleData}
      />
    ),
    owner: (
      <OwnerDetails
        selectedDetails={selectedForm}
        setSelectedProduct={setSelectedProduct}
        ownerDetails={ownerDetails}
      />
    ),
  };
  return (
    <>{formFieldMapping[selectedForm?.keys] || <p>Please Select a form</p>}</>
  );
};

export default EditLeadForm;
