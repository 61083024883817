import React from "react";
import CustomInput from "../../../../shared/CustomInput";

const UpdatedVehicleDetails = ({data }) => {
  return (
    <div className="mt-2">
      <div className="row">
        <div className="col-md-3">
          <div className="card">
            <div className="row m-2">
              <label>Previous Model</label>
              <CustomInput value={data?.prev_veh_model_name} disabled  />
            </div>
            <div className="row m-2">
              <label>Updated Model</label>
              <CustomInput  value={data?.updated_veh_model_name} disabled />
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="card">
            <div className="card">
              <div className="row m-2">
                <label>Previous Manufacturer</label>
                <CustomInput value=""  disabled />
              </div>
              <div className="row m-2">
                <label>Updated Manufacturer</label>
                <CustomInput disabled />
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="card">
            <div className="card">
              <div className="row m-2">
                <label>Previous Variant</label>
                <CustomInput disabled />
              </div>
              <div className="row m-2">
                <label>Updated Variant</label>
                <CustomInput disabled />
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="card">
            <div className="card">
              <div className="row m-2">
                <label>Previous Registration number</label>
                <CustomInput value={data?.prev_registration_number} disabled />
              </div>
              <div className="row m-2">
                <label>Updated Registration number</label>
                <CustomInput value={data?.updated_registration_number} disabled />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-md-3">
          <div className="card">
            <div className="row m-2">
              <label>Previous Chassis number</label>
              <CustomInput value={data?.prev_veh_chassis_number} disabled />
            </div>
            <div className="row m-2">
              <label>Updated Chassis number</label>
              <CustomInput value={data?.updated_veh_chassis_number} disabled />
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="card">
            <div className="row m-2">
              <label>Previous Engine number</label>
              <CustomInput value={data?.prev_veh_engine_number} disabled />
            </div>
            <div className="row m-2">
              <label>Updated Engine number</label>
              <CustomInput value={data?.updated_veh_engine_number} disabled />
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="card">
            <div className="row m-2">
              <label>Previous RTO</label>
              <CustomInput value={data?.prev_veh_rto_name} disabled />
            </div>
            <div className="row m-2">
              <label>Updated RTO</label>
              <CustomInput value={data?.updated_veh_rto_name} disabled />
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="card">
            <div className="row m-2">
              <label>Previous Colour</label>
              <CustomInput value={data?.prev_veh_color} disabled />
            </div>
            <div className="row m-2">
              <label>Updated Colour</label>
              <CustomInput value={data?.updated_veh_color} disabled />
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-md-3">
          <div className="card">
            <div className="row m-2">
              <label>Previous Fuel Type</label>
              <CustomInput disabled />
            </div>
            <div className="row m-2">
              <label>Updated Fuel Type</label>
              <CustomInput disabled />
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="card">
            <div className="row m-2">
              <label>Previous Sold By</label>
              <CustomInput disabled />
            </div>
            <div className="row m-2">
              <label>Updated Sold By</label>
              <CustomInput disabled />
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="card">
            <div className="row m-2">
              <label>Previous Manufacturing Year</label>
              <CustomInput value={data?.prev_veh_manufacture_year} disabled />
            </div>
            <div className="row m-2">
              <label>Updated Manufacturing Year</label>
              <CustomInput value={data?.updated_veh_manufacture_year} disabled />
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="card">
            <div className="row m-2">
              <label>Previous Sale Date</label>
              <CustomInput value={data?.prev_veh_sale_date} disabled />
            </div>
            <div className="row m-2">
              <label>Updated Sale Date</label>
              <CustomInput value={data?.updated_veh_sale_date} disabled />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdatedVehicleDetails;
