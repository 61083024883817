import React from "react";
import CustomInput from "../../../../shared/CustomInput";

const UpdatedAddonPACoverDetails = ({ data }) => {
  return (
    <div className="mt-2">
      <div className="row">
        <div className="col-md-3">
          <div className="card">
            <div className="row m-2">
              <label>Previous Passenger Assist</label>
              <CustomInput value={data?.updated_passenger_assist} disabled />
            </div>
            <div className="row m-2">
              <label>Updated Passenger Assist</label>
              <CustomInput value={data?.updated_passenger_assist}  disabled />
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="card">
            <div className="card">
              <div className="row m-2">
                <label>Previous Consumable Cover</label>
                <CustomInput value={data?.prev_consumable_cover}  disabled />
              </div>
              <div className="row m-2">
                <label>Updated Consumable Cover</label>
                <CustomInput value={data?.updated_consumable_cover} disabled />
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="card">
            <div className="card">
              <div className="row m-2">
                <label>Previous Roadside Assistance</label>
                <CustomInput disabled />
              </div>
              <div className="row m-2">
                <label>Updated Roadside Assistance</label>
                <CustomInput disabled />
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="card">
            <div className="card">
              <div className="row m-2">
                <label>Previous Depreciation Cover</label>
                <CustomInput value={data?.prev_depreciation_cover} disabled />
              </div>
              <div className="row m-2">
                <label>Updated Depreciation Cover</label>
                <CustomInput value={data?.updated_depreciation_cover} disabled />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdatedAddonPACoverDetails;
