import React from "react";
import "./CustomDropdown.scss";

function CustomDropdown({
  label,
  viral,
  options = [],
  name,
  value,
  onChange = () => {},
  optionLabel = "label",
  optionValue = "value",
  onBlur,
  register,
  error,
  placeholder = "",
  disabled=false
}) {
  return (
    <div className="custom-dropdown-container">
      <label className="form-label">{label}</label>
      {viral ? <span className="text-danger">*</span> : ""}
      <select
      disabled={disabled}
        menuPlacement="top"
        minMenuHeight={500}
        className="form-select custom-rounded"
        aria-label="Default select example"
        id="customDropdown"
        name={name}
        placeholder={placeholder}
        onBlur={onBlur}
        value={value}
        {...(register ? register : {})}
        onChange={(e) => {
          if (register) register.onChange(e);
          if (onChange) onChange(e);
        }}
      >
        <option value="">{placeholder}</option>
        {/* {options?.map((option) => (
          <option key={option.id} value={option[optionValue || "value"]}>
            {option[optionLabel || "label"]}
          </option>
        ))} */}
        {options?.map((option, index) => (
          <option
            key={option[optionValue || "value"] || index}
            value={option[optionValue || "value"]}
          >
            {option[optionLabel || "label"]}
          </option>
        ))}
      </select>
      {error && <div className="error-message-dropdown">{error.message}</div>}
    </div>
  );
}

export default CustomDropdown;
