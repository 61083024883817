import React, { useEffect, useState } from "react";
import { EDIT_DETAILS, EDIT_LEAD_DETAILS } from "./DefaultData";
import CustomButton from "../../../../shared/CustomButton";
import LeadListCard from "./LeadListCard";
import EditLeadForm from "./EditLeadForm";
import "./EditLead.scss";
import { useSelector } from "react-redux";
import { APIEditLeadInfo, APIForAproveReq } from "../../../../../api/common";
import moment from "moment";
import { displayToast } from "../../../../shared/toast/toastUtils";
import { useNavigate } from "react-router-dom";
import ROUTES_NAVIGATION from "../../../../../routes/routes";

const EditLeadInformation = ({
  handleDisacart = () => {},
  handleSave = () => {},
}) => {
  const [editDetailsFormList, setEditDetailsFormList] = useState([]);
  const [selectedDetails, setSelctedDetails] = useState();
  const navigate = useNavigate();
  // console.log("editDetailsFormList", editDetailsFormList);
  // console.log("selectedDetails", selectedDetails);
  const lastPolicyDetailsData = useSelector(
    (state) => state?.reduxFormManage?.leadDetails?.Last_Policy_Details
  );
  const leadId = useSelector(
    (state) => state?.reduxFormManage?.leadDetails?.Owner_Details?.CustomerID
  );

  const lastPolicyDetails = useSelector(
    (state) => state?.reduxFormManage?.leadDetails?.Last_Policy_Details
  );
  const addOnAndPaCover = useSelector(
    (state) => state?.reduxFormManage?.leadDetails?.Add_ON_PA_Cover
  );
  const vehicleDetails = useSelector(
    (state) => state?.reduxFormManage?.leadDetails?.Vehicle_Details
  );
  const ownerDetails = useSelector(
    (state) => state?.reduxFormManage?.leadDetails?.Owner_Details
  );

  const leadMasterId = ownerDetails?.LeadMasterId;
  const productId = ownerDetails?.LeadProductId;
  console.log("id", productId, leadMasterId);

  console.log(
    "LastPolicyDetails",
    useSelector(
      (state) =>
        state?.reduxFormManage?.leadDetails?.Last_Policy_Details
          ?.ClaimDuringLastYear?.value
    )
  );
  console.log(
    "AddOnPAcover",
    useSelector((state) => state?.reduxFormManage?.leadDetails?.Add_ON_PA_Cover)
  );
  console.log(
    "VehicleDetails",
    useSelector((state) => state?.reduxFormManage?.leadDetails?.Vehicle_Details)
  );
  console.log(
    "OwnerDetails",
    useSelector((state) => state?.reduxFormManage?.leadDetails?.Owner_Details)
  );
  console.log(
    "DobPayload",
    moment(ownerDetails?.DOB, [
      "YYYY-MM-DD",
      "YYYY-DD-MM",
      "DD-MM-YYYY",
    ]).format("yyyy-MM-DD")
  );

  const onSaveEditedData = async () => {
    console.log(
      lastPolicyDetails,
      "lastPolicyDetails",
      vehicleDetails,
      "vehicleDetails",
      ownerDetails,
      "ownerDetails"
    );
    const payload = {
      lead_product_id: leadMasterId,
      lead_id: productId,
      policy_number: lastPolicyDetails?.PolicyNumber || null,
      registration_number: vehicleDetails?.RegistrationNumber || null,
      // prev_policy_number: lastPolicyDetails?.PolicyNumber || null,
      insurance_company_id: lastPolicyDetails?.InsuranceCompany?.value || null,
      insurance_company_name:
        lastPolicyDetails?.InsuranceCompany?.label || null,
      // coverage_period: "" || null,
      policy_start_date: lastPolicyDetails?.CoveragePeriodFrom || null,
      policy_end_date: lastPolicyDetails?.CoveragePeriodTo || null,
      premium_amount: lastPolicyDetails?.PremiumAmount || null,
      // prev_ncb: lastPolicyDetails?.LastYearDiscount?.value || null,
      prev_claims: lastPolicyDetails?.ClaimDuringLastYear?.value || null,
      veh_make_id: vehicleDetails?.Manufacturer?.value || null,
      veh_model_id: vehicleDetails?.Model?.value || null,
      veh_variant_id: vehicleDetails?.Variant?.value || null,
      veh_engine_number: vehicleDetails?.EngineNumber || null,
      veh_chassis_number: vehicleDetails?.ChassisNumber || null,
      veh_rto_id: vehicleDetails?.RTO?.value || null,
      veh_color: vehicleDetails?.Colour || null,
      veh_manufacture_year: vehicleDetails?.ManufacturingYear || null,
      veh_sale_date: vehicleDetails?.SaleDate || null,
      prev_ncb: lastPolicyDetails?.LastYearDiscount?.value || null,
      passenger_assist: addOnAndPaCover?.PassengerAssist || null,
      consumable_cover: addOnAndPaCover?.ConsumableCover || null,
      roadside_assistance: addOnAndPaCover?.RoadsideAssistance || null,
      depreciation_cover: addOnAndPaCover?.DepreciationCover || null,
      // veh_sale_date: moment(vehicleDetails?.SaleDate, [
      //   "YYYY-MM-DD",
      //   "YYYY-DD-MM",
      //   "DD-MM-YYYY",
      // ]).format("yyyy-MM-DD"),
      lead_email: ownerDetails?.EmailId,
      lead_mobile_number: ownerDetails?.MobileNumber,
      lead_name: ownerDetails?.FullName,
      // DOB: moment(ownerDetails?.DOB, [
      //   "YYYY-MM-DD",
      //   "YYYY-DD-MM",
      //   "DD-MM-YYYY",
      // ]).format("yyyy-MM-DD"),
      DOB: ownerDetails?.DOB || null,
      address: ownerDetails?.Address || null,
    };

    // ---------Has to be change---------
    // const res = await APIEditLeadInfo(leadId, payload);
    const res = await APIForAproveReq(payload);

    if (res?.status) {
      // navigate(
      //   ROUTES_NAVIGATION.VEHICLE_INSURENCE_TOTALLEAD +
      //     "?productId=" +
      //     productId +
      //     "&leadId=" +
      //     leadId +
      //     "&details=" +
      //     "leadDetails"
      // );
      handleSave();
      displayToast({
        type: "success",
        title: res?.data?.message,
      });
    } else {
      displayToast({
        type: "error",
        title: res?.data?.message || res?.problem || "Something went wrong",
      });
    }
  };

  useEffect(() => {
    setEditDetailsFormList(EDIT_LEAD_DETAILS);
    setSelctedDetails(EDIT_LEAD_DETAILS[0]);
  }, []);
  return (
    <div className="editleadform">
      <div className="edit-lead-card-container">
        <div className="row g-2">
          <div className="col-4">
            <div className="card p-2">
              {editDetailsFormList?.map((value) => {
                return (
                  <LeadListCard
                    product={value}
                    selectProduct={selectedDetails}
                    // key={value?.id}
                    title={value?.title}
                    onClick={() => {
                      setSelctedDetails(value);
                    }}
                  />
                );
              })}
            </div>
          </div>
          <div className="col-8">
            <div className="card p-2">
              <p className=" fw-bold fs-6 mb-1 ps-2 text-black-80">
                {/* Edit {selectProduct?.editLabel} */}
              </p>
              <EditLeadForm
                selectedForm={selectedDetails}
                setSelectedProduct={setSelctedDetails}

                // error={error}
              />
            </div>
          </div>
          <div className="d-flex justify-content-end gap-2 addleadbtn mt-3">
            <CustomButton
              onClick={() => handleDisacart()}
              className={"text-dark-teal discard-btn"}
            >
              Discard
            </CustomButton>
            <CustomButton
              className={"text-blue save-btn"}
              onClick={() => {
                onSaveEditedData();
              }}
            >
              Save
            </CustomButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditLeadInformation;
