// import { Select } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import CustomButton from "../../../../shared/CustomButton";
import Select from "react-select";
import CustomDateInput from "../../../../shared/CustomDateInput";
import { useDispatch, useSelector } from "react-redux";
import { debounce, values } from "lodash";
import {
  EditLeadDetails,
  setFormManagerAsPerKey,
} from "../../../../../redux/FormData/reduxFormManage";
import {
  APIgetICOption,
  APIgetLastYearDiscount,
  APIgetRto,
} from "../../../../../api/common";
import moment from "moment/moment";

const LastPolicyDetails = ({ lastPolicyDetailsData }) => {
  const [lastYearDiscountOPtion, setLastYearDiscountOption] = useState([]);
  const icOption = useSelector((state) => state?.reduxFormManage?.icOption);
  // const data = useSelector((state) => state?.reduxFormManage);
  const dispatch = useDispatch();
  const [premiumAmount, setPremiumAmount] = useState();
  const [lastYearDiscount, setLastYearDicount] = useState(
    lastPolicyDetailsData?.LastYearDiscount
  );
  const [ic, setIc] = useState();
  const [coveragePeriodFrom, setCoveragePeriodFrom] = useState();
  const [coveragePeriodTo, setCoveragePeriodTo] = useState();
  const [claimDuringLastYear, setClaimDuringLastYear] = useState();
  const [policyNumber, setPolicyNumber] = useState();
  const [selectDate, setSelectDate] = useState(new Date());
  // const [icOption, setIcOption] = useState([]);

  console.log(
    "@@-->",
    lastPolicyDetailsData?.CoveragePeriodFrom,
    moment(lastPolicyDetailsData?.CoveragePeriodFrom, ["YYYY-MM-DD"]).format(
      "yyyy-MM-DD"
    )
  );

  // const getICOPtion = async () => {
  //   const res = await APIgetICOption();
  //   console.log("ICOPtion", res);
  // };

  // -------RTO option ----
  // const getRtoOption = async () => {
  //   const res = await APIgetRto();
  //   console.log("RTo res", res?.data?.data || []);
  //   if (res.ok) {
  //     setIcOption(res?.data?.data);
  //   } else {
  //     setIcOption([]);
  //   }
  // };
  // useEffect(() => {
  //   getICOPtion();
  // }, []);

  // useEffect(() => {
  //   getRtoOption();
  // }, []);

  const getLastYearDiscount = async () => {
    const resp = await APIgetLastYearDiscount();
    if (resp?.ok) {
      setLastYearDiscountOption(resp?.data?.data);
    } else {
      setLastYearDiscountOption([]);
    }
  };

  const {
    control,
    // handleSubmit,
    formState: { errors },
    // setValue,
  } = useForm({ reValidateMode: "onChange", mode: "onChange" });

  // console.log("Form State:", formState);

  // const debouncedDispatch = useCallback(
  //   debounce((event) => {
  //     dispatch(
  //       setFormManagerAsPerKey({
  //         type: "Last_Policy_Details",
  //         key: "LastYearDiscount",
  //         value: event.target.value,
  //       })
  //     );
  //   }, 500),
  //   [dispatch]
  // );

  const handleLastYearDiscount = useCallback(
    debounce((e) => {
      dispatch(
        setFormManagerAsPerKey({
          type: "Last_Policy_Details",
          key: "LastYearDiscount",
          value: e.target.value,
        })
      );
    }, 500),
    [dispatch]
  );

  // const handleLastYearDiscount = (e) => {
  //   onChange(e); // Call the provided onChange function
  //   debouncedDispatch(e); // Call the debounced dispatch
  // };

  const dateHandler = (date) => {
    console.log("!!!!", date);
    // // const parsedDate = new Date(coveragePeriodFrom);
    // const parsedDate = new Date(date);
    // if (!isNaN(parsedDate)) {
    //   // Method 1: Using toLocaleDateString (en-GB gives dd/mm/yyyy)
    //   const formattedDate1 = parsedDate
    //     .toLocaleDateString("en-GB")
    //     .replace(/\//g, "-");
    //   console.log("Formatted Date (Method 1):", formattedDate1);
    setCoveragePeriodFrom(date);

    // Method 2: Manual Formatting
    // const formattedDate2 = `${String(parsedDate.getDate()).padStart(
    //   2,
    //   "0"
    // )}-${String(parsedDate.getMonth() + 1).padStart(
    //   2,
    //   "0"
    // )}-${parsedDate.getFullYear()}`;
    // console.log("Formatted Date (Method 2):", formattedDate2);
    // } else {
    //   console.error("Invalid Date provided:", coveragePeriodFrom);
    // }
  };

  useEffect(() => {
    if (lastPolicyDetailsData) {
      setPolicyNumber(lastPolicyDetailsData?.PolicyNumber);
      setIc(lastPolicyDetailsData?.InsuranceCompany);
      setLastYearDicount(lastPolicyDetailsData?.LastYearDiscount);
      // setCoveragePeriodFrom(
      //   moment(lastPolicyDetailsData?.CoveragePeriodFrom).format("DD-MM-YYYY")
      // );
      // setCoveragePeriodFrom(lastPolicyDetailsData?.CoveragePeriodFrom);
      setCoveragePeriodTo(lastPolicyDetailsData?.CoveragePeriodTo);
      setPremiumAmount(lastPolicyDetailsData?.PremiumAmount);
      setClaimDuringLastYear(lastPolicyDetailsData?.ClaimDuringLastYear);
    }
  }, [lastPolicyDetailsData]);

  useEffect(() => {
    getLastYearDiscount();
  }, []);
  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-6" style={{ padding: "5px 5px 15px" }}>
          <Controller
            control={control}
            name={"policyNumber"}
            defaultValue={policyNumber}
            rules={{
              required: {
                value: true,
                message: "This field is required to be filled*",
              },
              maxLength: {
                value: 25,
                message: `Max 25 characters allowed`,
              },
            }}
            render={({
              field: { onChange, onBlur, value },
              fieldState: { error },
            }) => (
              <div className="custom-input-wrapper">
                <label htmlFor="customInput" className="form-label">
                  {"Policy Number"}
                </label>
                {/* <span className="text-danger">*</span> */}
                <div className="input-with-prefix">
                  <input
                    type={"text"}
                    className="form-control form-control-lg"
                    placeholder={"Enter Policy Number"}
                    value={lastPolicyDetailsData?.PolicyNumber}
                    onChange={(e) => onChange(e)}
                    onBlur={onBlur}
                    disabled
                  />
                </div>
                {error && (
                  <div className="error-message-input">{error.message}</div>
                )}
              </div>
            )}
          />
        </div>

        <div className="col-sm-6" style={{ padding: "5px 5px 15px" }}>
          <Controller
            control={control}
            name={"ic"}
            defaultValue={ic || null}
            rules={{
              required: {
                value: true,
                message: "This field is required",
              },
              // maxLength: {
              //   value: 10,
              //   message: `Max 10 characters allowed`,
              // },
              // pattern: {
              //   value: /^[6-9]\d{9}$/,
              //   message: "Please Enter Vailed Mobile No.",
              // },
            }}
            render={({
              field: { onChange, onBlur, value },
              fieldState: { error },
            }) => (
              <div className="custom-input-wrapper">
                <label htmlFor="customInput" className="form-label">
                  {"Insurance Company"}
                </label>
                {/* <span className="text-danger">*</span> */}
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  isClearable
                  value={ic}
                  // value={lastPolicyDetailsData?.InsuranceCompany}
                  onChange={(e) => {
                    //   // onChange(e?.value);
                    setIc(e || {});
                    console.log("e?.value", e);
                    // dispatch(ic);
                    dispatch(
                      setFormManagerAsPerKey({
                        type: "Last_Policy_Details",
                        key: "InsuranceCompany",
                        value: e,
                      })
                    );
                    // dispatch(
                    //   setFormManagerAsPerKey({
                    //     type: "leadDetails",
                    //     key: "Last_Policy_Details",
                    //     value: e,
                    //   })
                    // );
                  }}
                  options={
                    icOption &&
                    icOption.map((item) => ({
                      label: item.name,
                      value: item.id,
                    }))
                  }
                  placeholder={"Select IC"}
                />
                {error && (
                  <div className="error-message-input">{error.message}</div>
                )}
              </div>
            )}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6" style={{ padding: "5px 5px 15px" }}>
          <Controller
            control={control}
            defaultValue={
              // coveragePeriodFrom ? new Date(coveragePeriodFrom) : null
              lastPolicyDetailsData?.CoveragePeriodFrom
            }
            name={"coveragePeriodFrom"}
            // rules={{
            //   required: {
            //     value: true,
            //     message: "This field is required to be filled*",
            //   },
            //   maxLength: {
            //     value: 10,
            //     message: `Max 10 characters allowed`,
            //   },
            //   pattern: {
            //     value: /^[6-9]\d{9}$/,
            //     message: "Please Enter Vailed Mobile No.",
            //   },
            // }}
            render={({
              field: { onChange, onBlur, value },

              fieldState: { error },
            }) => (
              <div className="custom-input-wrapper">
                <label htmlFor="customInput" className="form-label">
                  {"Coverage Peroid from"}
                </label>
                <div className="input-with-prefix">
                  <CustomDateInput
                    placeholderText="DD/MM/YYYY"
                    // minDate={new Date()}
                    isDateRange={false}
                    className="from-date-input ms-1"
                    selected={moment(
                      lastPolicyDetailsData?.CoveragePeriodFrom,
                      ["YYYY-MM-DD", "YYYY-DD-MM", "DD-MM-YYYY"]
                    ).format("yyyy-MM-DD")}
                    // selected={lastPolicyDetailsData?.CoveragePeriodFrom}
                    onChange={(date) => {
                      // dateHandler(date);

                      dispatch(
                        setFormManagerAsPerKey({
                          type: "Last_Policy_Details",
                          key: "CoveragePeriodFrom",
                          // value: date,
                          value: moment(date, [
                            "YYYY-MM-DD",
                            "YYYY-DD-MM",
                            "DD-MM-YYYY",
                          ]).format("yyyy-MM-DD"),
                        })
                      );
                    }}
                  />
                  {/* <CustomDateInput
                    label="Next Follow select Date"
                    minDate={new Date()}
                    selected={coveragePeriodFrom}
                    required
                    isDateRange={false}
                    placeholderText="-- date --"
                    className="form-date-input"
                    onChange={(date) => {
                      // removeErrorState("selectDate");
                      setCoveragePeriodFrom(date);

                      // setSelectedTime(moment(new Date()).format("H:mm"));
                      // removeErrorState("selectedTime");
                    }}
                    // error={!!error?.selectDate}
                    // errorsMessage={error.selectDate}
                  /> */}
                </div>
                {error && (
                  <div className="error-message-input">{error.message}</div>
                )}
              </div>
            )}
          />
        </div>
        <div className="col-sm-6" style={{ padding: "5px 5px 15px" }}>
          <Controller
            control={control}
            defaultValue={moment(lastPolicyDetailsData?.CoveragePeriodFrom, [
              "YYYY-MM-DD",
              "YYYY-DD-MM",
              "DD-MM-YYYY",
            ]).format("yyyy-MM-DD")}
            name={"coveragePeroidTo"}
            // rules={{
            //   required: {
            //     value: true,
            //     message: "This field is required to be filled*",
            //   },
            //   pattern: {
            //     value:
            //       /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.(com|in|org|co|net)$/,
            //     message: "Invalid Email Id format",
            //   },
            // }}
            render={({
              field: { onChange, onBlur, value },
              fieldState: { error },
            }) => (
              <div className="custom-input-wrapper">
                <label htmlFor="customInput" className="form-label">
                  {"Coverage Peroid to"}
                </label>
                <div className="input-with-prefix">
                  <CustomDateInput
                    placeholderText="DD/MM/YYYY"
                    minDate={new Date()}
                    isDateRange={false}
                    className="from-date-input ms-1"
                    selected={moment(lastPolicyDetailsData?.CoveragePeriodTo, [
                      "YYYY-MM-DD",
                      "YYYY-DD-MM",
                      "DD-MM-YYYY",
                    ]).format("yyyy-MM-DD")}
                    onChange={(date) => {
                      console.log("@@@", date);
                      // setCoveragePeriodTo(date);
                      dispatch(
                        setFormManagerAsPerKey({
                          type: "Last_Policy_Details",
                          key: "CoveragePeriodTo",
                          value: moment(date, [
                            "YYYY-MM-DD",
                            "YYYY-DD-MM",
                            "DD-MM-YYYY",
                          ]).format("yyyy-MM-DD"),
                        })
                      );
                    }}
                  />
                </div>

                {error && (
                  <div className="error-message-input">{error.message}</div>
                )}
              </div>
            )}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6" style={{ padding: "5px 5px 15px" }}>
          <Controller
            control={control}
            name={"premiumAmount"}
            defaultValue={premiumAmount}
            rules={{
              // required: {
              //   value: true,
              //   message: "This field is required to be filled*",
              // },
              maxLength: {
                value: 25,
                message: `Max 25 characters allowed`,
              },
            }}
            render={({
              field: { onChange, onBlur, value },
              fieldState: { error },
            }) => (
              <div className="custom-input-wrapper">
                <label htmlFor="customInput" className="form-label">
                  {"Premium Amount"}
                </label>
                <div className="input-with-prefix">
                  <input
                    type={"text"}
                    className="form-control form-control-lg"
                    placeholder={"Enter Amount"}
                    value={premiumAmount}
                    onChange={(e) => {
                      value = e.target.value;
                      const onlyNumbers = value.replace(/[^0-9]/g, "");
                      onChange(e);
                      // setPremiumAmount(onlyNumbers);
                      dispatch(
                        setFormManagerAsPerKey({
                          type: "Last_Policy_Details",
                          key: "PremiumAmount",
                          value: e.target.value,
                        })
                      );
                    }}
                    // onBlur={onBlur}
                  />
                </div>
                {error && (
                  <div className="error-message-input">{error.message}</div>
                )}
              </div>
            )}
          />
        </div>

        <div className="col-sm-6" style={{ padding: "5px 5px 15px" }}>
          <Controller
            control={control}
            name={"lastYearDiscount"}
            defaultValue={lastYearDiscount}
            rules={{
              required: {
                value: true,
                message: "This field is required",
              },
              // maxLength: {
              //   value: 10,
              //   message: `Max 10 characters allowed`,
              // },
              // pattern: {
              //   value: /^[6-9]\d{9}$/,
              //   message: "Please Enter Vailed Mobile No.",
              // },
            }}
            render={({
              field: { onChange, onBlur, value },
              fieldState: { error },
            }) => (
              <div className="custom-input-wrapper">
                <label htmlFor="customInput" className="form-label">
                  {"Last Year Discount (%)"}
                </label>
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  isClearable
                  // value={lastPolicyDetailsData?.ClaimDuringLastYear}
                  onChange={(e) => {
                    // onChange(e);
                    dispatch(
                      setFormManagerAsPerKey({
                        type: "Last_Policy_Details",
                        key: "LastYearDiscount",
                        value: e,
                      })
                    );
                  }}
                  options={lastYearDiscountOPtion && lastYearDiscountOPtion}
                  placeholder={"Last Year Discount"}
                />
                {error && (
                  <div className="error-message-input">{error.message}</div>
                )}
              </div>
            )}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-sm-6" style={{ padding: "5px 5px 15px" }}>
          <Controller
            control={control}
            name={"claimDuringLastYear"}
            defaultValue={claimDuringLastYear}
            rules={{
              required: {
                value: true,
                message: "This field is required",
              },
              // maxLength: {
              //   value: 10,
              //   message: `Max 10 characters allowed`,
              // },
              // pattern: {
              //   value: /^[6-9]\d{9}$/,
              //   message: "Please Enter Vailed Mobile No.",
              // },
            }}
            render={({
              field: { onChange, onBlur, value },
              fieldState: { error },
            }) => (
              <div className="custom-input-wrapper">
                <label htmlFor="customInput" className="form-label">
                  {"Claim During Last Year"}
                </label>
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  isClearable
                  value={claimDuringLastYear}
                  onChange={(e) => {
                    onChange(e);
                    // setClaimDuringLastYear(e);
                    dispatch(
                      setFormManagerAsPerKey({
                        type: "Last_Policy_Details",
                        key: "ClaimDuringLastYear",
                        value: e,
                      })
                    );
                  }}
                  options={[
                    { value: 1, label: "Yes" },
                    { value: 0, label: "No" },
                  ]}
                  placeholder={"Select Claim"}
                />
                {error && (
                  <div className="error-message-input">{error.message}</div>
                )}
              </div>
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default LastPolicyDetails;
