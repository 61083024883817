import React, { useState, useEffect } from "react";
import { displayToast } from "../../../shared/toast/toastUtils";
import RightSideContainer from "../../../../pages/RightSideContainer";
import { useNavigate, useLocation } from "react-router-dom";
import quantique from "../../../../../src/assets/img/photos/quantique.svg";
import { APIForgotPassword, APIValidateOtp } from "../../../../api/common";
import "./Otp.scss";

const OTPComponent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { email } = location.state || {};
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [timer, setTimer] = useState(59);
  const [isResendDisabled, setIsResendDisabled] = useState(true);
  useEffect(() => {
    let countdown;
    if (timer > 0) {
      countdown = setInterval(() => {
        setTimer((prev) => {
          if (prev === 1) {
            clearInterval(countdown);
            setIsResendDisabled(false);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    }

    return () => clearInterval(countdown);
  }, [timer]);

  const handleOtpChange = (element, index) => {
    const value = element.value.replace(/[^0-9]/g, "");
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    if (value && element.nextSibling) {
      element.nextSibling.focus();
    }
  };

  const handleResendOtp = async () => {
    console.log("Resend OTP clicked");
    setOtp(new Array(6).fill(""));
    setTimer(59);
    setIsResendDisabled(true);
    if (!email) {
      displayToast({
        type: "error",
        title: "Email is required to resend OTP.",
      });
      return;
    }
    const payload = { email, action: "resend" };
    console.log("payload", payload);

    try {
      const response = await APIForgotPassword(payload);
      if (response?.status === 200) {
        displayToast({
          type: "success",
          title: "OTP Resent Successfully!",
        });
      } else {
        displayToast({
          type: "error",
          title: "Failed to resend OTP. Please try again.",
        });
      }
    } catch (error) {
      console.error("Error with resending OTP:", error);
      displayToast({
        type: "error",
        title: "Failed to resend OTP. Please try again.",
      });
    }
  };

  const handleOtpSubmit = async () => {
    const otpCode = otp.join("");
    console.log("otpCode", otpCode);
    if (otpCode.length === 6) {
      const payload = { otp: otpCode, action: "validate", email };
      await handleOtpRequest(
        payload,
        "OTP Verified Successfully!",
        "Invalid OTP. Please try again."
      );
    } else {
      displayToast({ type: "error", title: "Please enter a valid OTP." });
    }
  };
  const handleOtpRequest = async (payload, successMessage, errorMessage) => {
    try {
      const response = await APIValidateOtp(payload);

      if (response?.status === 200) {
        displayToast({
          type: "success",
          title: successMessage,
        });
        if (payload.action === "validate") {
          navigate("/change-password", { state: { email } });
        }
      } else {
        displayToast({
          type: "error",
          title: errorMessage,
        });
      }
    } catch (error) {
      console.error("Error with OTP request:", error);
      displayToast({
        type: "error",
        title: errorMessage,
      });
    }
  };
  return (
    <div className="container-fluid">
      <div className="row vh-100">
        <RightSideContainer />
        <div
          className="col-md-6 d-flex align-items-center justify-content-center"
          style={{ backgroundColor: "white" }}
        >
          <div className="login-box p-4">
            <div className="text-center mb-5">
              <img src={quantique} alt="Quantique Logo" className="img-fluid" />
            </div>
            <h4 className="mb-4 text-black">Verify your Email.</h4>
            <p>
              Enter OTP (One Time Password) that has been sent to your Email ID
              <br />
              <span className="text-gray email-id">
                {email || "Unknown Email"}
              </span>
            </p>

            <div className="otp-container">
              {otp.map((_, index) => (
                <input
                  key={index}
                  type="text"
                  maxLength="1"
                  className="otp-input-box"
                  value={otp[index]}
                  onChange={(e) => handleOtpChange(e.target, index)}
                  onFocus={(e) => e.target.select()}
                />
              ))}
            </div>

            <div className="text-end mt-3 mb-4 d-flex justify-content-between">
              {timer > 0 ? (
                <>
                  <span
                    className={`${
                      isResendDisabled
                        ? "otp-box-shade disabled"
                        : "otp-box-shade text-primary"
                    }`}
                  >
                    Resend OTP
                  </span>
                  <span className="text-danger">
                    <b>{`00:${timer < 10 ? `0${timer}` : timer}`}</b>
                  </span>
                </>
              ) : (
                <span
                  className={`${
                    isResendDisabled ? "disabled" : "text-primary"
                  }`}
                  style={{
                    cursor: isResendDisabled ? "not-allowed" : "pointer",
                  }}
                  onClick={!isResendDisabled ? handleResendOtp : null}
                >
                  Resend OTP
                </span>
              )}
            </div>
            <div className="d-flex justify-content-between">
              <button
                className="btn btn-block login-button"
                style={{
                  backgroundColor: "#4285F4",
                  color: "white",
                  borderRadius: "10px",
                  width: "100%",
                }}
                onClick={handleOtpSubmit}
              >
                Verify OTP
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OTPComponent;
